<div class="materials-form-window">
    <div class="materials-form-header">
        <img class="myce-logo" src="assets/images/MCE_logo_white.png" alt="MyClassroom Economy logo">
    </div>
    <div class="materials-form-body">
        <form class="materials-form" [formGroup]="materialsForm" (ngSubmit)="onSubmit()" focusInvalidInput>
            
          <div class="materials-form-fields">

            <div class="materials-form-title">
                Help us learn more about you!
            </div>

            <div class="materials-form-description">
                Please complete the form below in order to download My Classroom Economy materials.
            </div>

            <c11n-details
                expandLabelText="Why we are asking for your information"
                collapseLabelText="Why we are asking for your information"
                iconPosition="Trailing"
                labelAlign="left"
                labelWeight="normal"
                variant="primary"
                ariaLabelText="Why we are asking for your information">
                <div class="data-collection-message" [innerHTML]="dataCollectionMessage"></div>
            </c11n-details>

            <div class="row form-row">
              <div class="col-6">
                <div class="form-group">
                    <label 
                      for="formFieldFirstName" 
                      class="form-label inputLabel">
                      First Name <span class="required-asterisk">*</span></label>
                    <c11n-input 
                      [size]="'large'"
                      [hasError]="isSubmittedAndInvalid && (
                        materialsForm.controls['firstName'].hasError('required') || 
                        materialsForm.controls['firstName'].hasError('maxlength') ||
                        materialsForm.controls['firstName'].hasError('pattern'))">
                      <input 
                        c11nInput formControlName="firstName" 
                        type="text" 
                        class="form-control shadow-none"
                        id="formFieldFirstName" 
                        autocomplete="off"
                        [attr.aria-describedby]="'first-name-description'"
                        [attr.aria-label]="'First Name'" />
                    </c11n-input>
                    <c11n-hint-error 
                      [hasError]="isSubmittedAndInvalid && materialsForm.controls['firstName'].hasError('required')"
                      *ngIf="isSubmittedAndInvalid && materialsForm.controls['firstName'].hasError('required')"
                      [hintErrorId]="'first-name-description'" 
                      [errorText]="'First name is required.'"
                      [size]="'large'">
                    </c11n-hint-error>
                    <c11n-hint-error 
                      [hasError]="isSubmittedAndInvalid && materialsForm.controls['firstName'].hasError('maxlength')"
                      *ngIf="isSubmittedAndInvalid && materialsForm.controls['firstName'].hasError('maxlength')"
                      [hintErrorId]="'first-name-description'"
                      [errorText]="'First name must be between 1-20 characters.'" 
                      [size]="'large'">
                    </c11n-hint-error>
                    <c11n-hint-error 
                      [hasError]="isSubmittedAndInvalid && 
                        materialsForm.controls['firstName'].hasError('pattern') &&
                        !(materialsForm.controls['firstName'].hasError('maxlength'))"
                      *ngIf="isSubmittedAndInvalid && 
                        materialsForm.controls['firstName'].hasError('pattern') &&
                        !(materialsForm.controls['firstName'].hasError('maxlength'))"
                      [hintErrorId]="'first-name-description'"
                      [errorText]="'First name is allowed the following special characters: \'-.&_ ,'" 
                      [size]="'large'">
                    </c11n-hint-error>
                </div>
              </div>
  
              <div class="col-6">
                <div class="form-group">
                  <label 
                    for="formFieldLastName" 
                    class="form-label inputLabel">
                    Last Name</label>
                  <c11n-input 
                    [size]="'large'"
                    [hasError]="isSubmittedAndInvalid && (
                      materialsForm.controls['lastName'].hasError('maxlength') ||
                      materialsForm.controls['lastName'].hasError('pattern'))">
                    <input 
                      c11nInput formControlName="lastName" 
                      type="text" 
                      class="form-control shadow-none"
                      id="formFieldLastName" 
                      autocomplete="off"
                      [attr.aria-describedby]="'last-name-description'"
                      [attr.aria-label]="'Last Name'" />
                  </c11n-input>
                  <c11n-hint-error 
                      [hasError]="isSubmittedAndInvalid && materialsForm.controls['lastName'].hasError('maxlength')"
                      *ngIf="isSubmittedAndInvalid && materialsForm.controls['lastName'].hasError('maxlength')"
                      [hintErrorId]="'last-name-description'"
                      [errorText]="'Last name must not be greater than 35 characters.'" 
                      [size]="'large'">
                    </c11n-hint-error>
                  <c11n-hint-error 
                      [hasError]="isSubmittedAndInvalid && materialsForm.controls['lastName'].getError('pattern') && !(materialsForm.controls['lastName'].hasError('maxlength'))"
                      *ngIf="isSubmittedAndInvalid && materialsForm.controls['lastName'].getError('pattern') && !(materialsForm.controls['lastName'].hasError('maxlength'))"
                      [hintErrorId]="'last-name-description'"
                      [errorText]="'Last name is allowed the following special characters: \'-.&_ ,'" 
                      [size]="'large'">
                    </c11n-hint-error>
                </div>
              </div>
            </div>

            <div class="row form-row">
              <div class="col-6">
                <div class="form-group">
                  <label 
                    for="formFieldEmail" 
                    class="form-label inputLabel">
                    Email Address <span class="required-asterisk">*</span></label>
                  <c11n-input 
                    [size]="'large'"
                    [hasError]="isSubmittedAndInvalid && (
                      materialsForm.controls['email'].getError('required') || 
                      materialsForm.controls['email'].getError('pattern') || 
                      materialsForm.controls['email'].hasError('maxlength'))">
                    <input 
                      c11nInput 
                      formControlName="email" 
                      type="email" 
                      class="form-control shadow-none"
                      id="formFieldEmail" 
                      autocomplete="off"
                      [attr.aria-describedby]="'email-description'"
                      [attr.aria-label]="'E-mail address'" />
                  </c11n-input>
                  <c11n-hint-error 
                    [hasError]="isSubmittedAndInvalid && 
                      (materialsForm.controls['email'].getError('required') || materialsForm.controls['email'].getError('pattern'))"
                    *ngIf="isSubmittedAndInvalid && 
                      (materialsForm.controls['email'].getError('required') || materialsForm.controls['email'].getError('pattern'))"
                    [hintErrorId]="'email-description'"
                    [errorText]="'Invalid email address.'" 
                    [size]="'large'">
                  </c11n-hint-error>
                  <c11n-hint-error 
                    [hasError]="isSubmittedAndInvalid && 
                      materialsForm.controls['email'].hasError('maxlength') &&
                      !(materialsForm.controls['email'].getError('required') || materialsForm.controls['email'].getError('pattern'))"
                    *ngIf="isSubmittedAndInvalid && 
                      materialsForm.controls['email'].hasError('maxlength') &&
                      !(materialsForm.controls['email'].getError('required') || materialsForm.controls['email'].getError('pattern'))"
                    [hintErrorId]="'email-description'"
                    [errorText]="'Email address must be between 1-254 characters.'" 
                    [size]="'large'">
                  </c11n-hint-error>
                </div>
              </div>

              <div class="col-6">
                <div class="form-group">
                  <label 
                    for="formFieldschoolName" 
                    class="form-label inputLabel">
                    School or Organization <span class="required-asterisk">*</span></label>
                  <c11n-input 
                    [size]="'large'"
                    [hasError]="isSubmittedAndInvalid && (
                      materialsForm.controls['schoolName'].getError('required') || 
                      materialsForm.controls['schoolName'].getError('pattern') || 
                      materialsForm.controls['schoolName'].hasError('maxlength'))">
                    <input 
                      c11nInput formControlName="schoolName" 
                      type="text" 
                      class="form-control shadow-none"
                      id="formFieldschoolName" 
                      autocomplete="off"
                      [attr.aria-describedby]="'school-name-description'"
                      [attr.aria-label]="'School or Organization'" />
                  </c11n-input>
                  <c11n-hint-error 
                    [hasError]="isSubmittedAndInvalid && materialsForm.controls['schoolName'].getError(
                      'required'
                    )"
                    *ngIf="isSubmittedAndInvalid && materialsForm.controls['schoolName'].getError(
                      'required'
                    )"
                    [hintErrorId]="'school-name-description'" 
                    [errorText]="'School or organization is required.'"
                    [size]="'large'">
                  </c11n-hint-error>
                  <c11n-hint-error 
                    [hasError]="isSubmittedAndInvalid && materialsForm.controls['schoolName'].getError('maxlength')"
                    *ngIf="isSubmittedAndInvalid && materialsForm.controls['schoolName'].getError('maxlength')"
                    [hintErrorId]="'school-name-description'"
                    [errorText]="'School or organization name must be between 1-100 characters.'" 
                    [size]="'large'">
                  </c11n-hint-error>
                  <c11n-hint-error 
                    [hasError]="isSubmittedAndInvalid && 
                      materialsForm.controls['schoolName'].getError('pattern') &&
                      !(materialsForm.controls['schoolName'].getError('maxlength'))" 
                    *ngIf="isSubmittedAndInvalid && 
                      materialsForm.controls['schoolName'].getError('pattern') &&
                      !(materialsForm.controls['schoolName'].getError('maxlength'))" 
                    [hintErrorId]="'school-name-description'" 
                    [errorText]="'School or organization name is allowed the following special characters: \'-.&_ ,'" 
                    [size]="'large'">
                  </c11n-hint-error>
                </div>
              </div>
            </div>

            <div class="row form-row">
              <div class="col-6">
                <div class="form-group">
                  <label for="formFieldZipCode" class="form-label inputLabel">
                    School Zip Code / Postal Code <span class="required-asterisk">*</span>
                  </label>
                  <c11n-input 
                    [size]="'large'"
                    [hasError]="
                      isSubmittedAndInvalid && 
                      (materialsForm.controls['schoolZipCode'].getError('inValidZip') || materialsForm.controls['schoolZipCode'].getError('required'))
                    ">
                    <input c11nInput 
                      formControlName="schoolZipCode" 
                      type="text" 
                      class="form-control shadow-none"
                      id="formFieldZipCode" 
                      autocomplete="off"
                      [attr.aria-describedby]="'zipcode-description'" 
                      [attr.aria-label]="'School Zip Code / Postal Code'" />
                  </c11n-input>
                  <c11n-hint-error [hasError]="
                      isSubmittedAndInvalid && 
                      (materialsForm.controls['schoolZipCode'].getError('inValidZip') || materialsForm.controls['schoolZipCode'].getError('required'))
                    " *ngIf="
                      isSubmittedAndInvalid && 
                      (materialsForm.controls['schoolZipCode'].getError('inValidZip') || materialsForm.controls['schoolZipCode'].getError('required'))
                    " [hintErrorId]="'zipcode-description'" 
                    [errorText]="'Invalid US zip code / Canada postal code.'" 
                    [size]="'large'">
                  </c11n-hint-error>
                </div>
              </div>

              <div class="col-6">
                <div class="form-group">
                  <label
                    for="formFieldGradeLevel" 
                    class="form-label inputLabel"
                    >Grade Level(s) <span class="required-asterisk">*</span>
                  </label>
                  <c11n-dropdown
                    id="formFieldGradeLevel"
                    [size]="'large'"
                    (stateChange)="getSelectedGrades($event)"
                    [direction]="'below'"
                    [selectedText]="this.selectedGrades"
                    [placeholderText]="''"
                    [hideLabel]="true"
                    [variant]="'multi-select basic'"
                    [activeItems]=""
                    [menuData]="gradeLevelList"
                    [attr.aria-label]="'Grade Level(s)'"
                    [ngClass]="
                    { 
                      'dropdown-error': isSubmittedAndInvalid && materialsForm.controls['gradeLevel'].getError(
                        'required'
                      ), 
                      'dropdown-valid': !(isSubmittedAndInvalid && materialsForm.controls['gradeLevel'].getError(
                        'required'
                      ))
                    }">
                  </c11n-dropdown>
                  <c11n-hint-error
                    [hasError]="isSubmittedAndInvalid && materialsForm.controls['gradeLevel'].getError(
                      'required'
                    )"
                    [errorText]="
                      'Grade level(s) is required.'
                    "
                    [size]="'large'">
                  </c11n-hint-error>
                </div>
              </div>
            </div>

            <div class="row form-row">
              <div class="col-8">
                <div class="form-group">
                  <label 
                    for="formFieldReference" 
                    class="form-label inputLabel">
                    How did you hear about us? <span class="required-asterisk">*</span></label>
                  <c11n-dropdown
                    class="form-select reference-dropdown"
                    id="formFieldReference"
                    [size]="'large'"
                    (stateChange)="getSelectedReferral($event)"
                    [direction]="'above'"
                    [placeholderText]="'Select...'"
                    [hideLabel]="true"
                    [activeItems]=""
                    [menuData]="referenceList"
                    [attr.aria-label]="'How did you hear about us?'"
                    [ngClass]="
                    { 
                      'dropdown-error': isSubmittedAndInvalid && materialsForm.controls['reference'].getError(
                        'required'
                      ), 
                      'dropdown-valid': !(isSubmittedAndInvalid && materialsForm.controls['reference'].getError(
                        'required'
                      ))
                    }">
                  </c11n-dropdown>
                  <c11n-hint-error
                    [hasError]="isSubmittedAndInvalid && materialsForm.controls['reference'].getError(
                      'required'
                    )"
                    [errorText]="
                      'Selection of referral is required.'
                    "
                    [size]="'large'">
                  </c11n-hint-error>
                </div>
              </div>
            </div>

            <div *ngIf="selectedReferral === 'partnership'" class="row form-row">
              <div class="col-8">
                <div class="form-group">
                  <label 
                    for="formFieldPartnership" 
                    class="form-label inputLabel">
                    Which partnership?</label>
                  <c11n-dropdown
                    class="dropdown-valid form-select"
                    id="formFieldPartnership"
                    [size]="'large'"
                    (stateChange)="getSelectedPartnership($event)"
                    [direction]="'above'"
                    [placeholderText]="'Select...'"
                    [hideLabel]="true"
                    [activeItems]=""
                    [menuData]="partnershipList"
                    [attr.aria-label]="'Which partnership?'">
                  </c11n-dropdown>
                </div>
              </div>
            </div>

            <div *ngIf="selectedReferral === 'conference'" class="row form-row">
              <div class="col-8">
                <div class="form-group">
                  <label 
                    for="formFieldConference" 
                    class="form-label inputLabel">
                    Which conference?</label>
                  <c11n-dropdown
                    class="dropdown-valid form-select"
                    id="formFieldConference"
                    [size]="'large'"
                    (stateChange)="getSelectedConference($event)"
                    [direction]="'above'"
                    [placeholderText]="'Select...'"
                    [hideLabel]="true"
                    [activeItems]=""
                    [menuData]="conferenceList"
                    [attr.aria-label]="'Which conference?'">
                  </c11n-dropdown>
                </div>
              </div>
            </div>

            <div class="checkbox-group">
              <div class="row form-row--checkbox">
                <div class="form-group--checkbox">
                  <c11n-checkbox 
                    labelText="I agree to the Terms & Conditions"
                    hideLabel="true">
                    <input 
                        c11nCheckboxInput formControlName="isTermsAndConditionsAccepted" 
                        id="formFieldTerms" 
                        type="checkbox"
                        [attr.aria-label]="'Checkbox for accepting the Terms & Conditions'"/>
                  </c11n-checkbox>
                  <div class="form-label--checkbox"
                    >I agree to the&nbsp;
                    <a c11n-link 
                      [href]="signupExternalLinks.terms_conditions_url"
                      target="_blank"
                      >Terms & Conditions</a>&nbsp;<span class="required-asterisk">*</span>
                  </div>
                </div>
                <c11n-hint-error
                  [hasError]="isSubmittedAndInvalid && materialsForm.controls['isTermsAndConditionsAccepted'].getError(
                    'required'
                  )"
                  [errorText]="
                    'Agreement with the Terms & Conditions is required.'
                  "
                  [size]="'large'">
                </c11n-hint-error>
              </div>

              <div class="row form-row--checkbox">
                <div class="form-group--checkbox">
                  <c11n-checkbox 
                    labelText="I agree to the Privacy Policy"
                    hideLabel="true">
                    <input 
                        c11nCheckboxInput formControlName="isPrivacyPolicyAccepted" 
                        id="formFieldPrivacyPolicy" 
                        type="checkbox"
                        [attr.aria-label]="'Checkbox for accepting the Privacy Policy'"/>
                  </c11n-checkbox>
                  <div class="form-label--checkbox"
                    >I agree to the&nbsp;
                    <a c11n-link 
                      [href]="signupExternalLinks.privacy_policy_url"
                      target="_blank"
                      >Privacy Policy</a>&nbsp;<span class="required-asterisk">*</span>
                  </div>
                </div>
                <c11n-hint-error
                  [hasError]="isSubmittedAndInvalid && materialsForm.controls['isPrivacyPolicyAccepted'].getError(
                    'required'
                  )"
                  [errorText]="
                    'Agreement with the Privacy Policy is required.'
                  "
                  [size]="'large'">
                </c11n-hint-error>
              </div>
            </div>
          </div>

          <div class="remember-response-message">
              We won't ask you for this information again for subsequent classroom material downloads
              during this browser session. If you exit the browser and return to download additional 
              materials, you will need to complete this form again.
          </div>
          <div class="required-message">
              <span class="required-asterisk">*</span> Required
          </div>

          <re-captcha
            formControlName="captchaResponse"
            siteKey="6LdApAYqAAAAAOQW_aWW2F_vYOZZU36XKu2dWfaU"
            class="center-captcha">
          </re-captcha>
          <c11n-hint-error
            [hasError]="isSubmittedAndInvalid && materialsForm.controls['captchaResponse'].getError('required')"
            *ngIf="isSubmittedAndInvalid && materialsForm.controls['captchaResponse'].getError('required')"
            [hintErrorId]="'captchaResponse-description'"
            [errorText]="'Valid reCAPTCHA Required'" 
            [size]="'large'">
          </c11n-hint-error>

          <div class="button-group">
              <c11n-button
                  class="submit-button"
                  labelText="Download materials"
                  buttonType="submit"
                  size="large"
                  [isWaiting]="isSaving"
                  [attr.aria-label]="'Download materials'"
                  [attr.aria-describedby]="'submit-description'">
              </c11n-button>
              <c11n-button
                  labelText="Cancel"
                  buttonType="button"
                  variant="secondary"
                  size="large"
                  [attr.aria-label]="'Cancel'"
                  [attr.aria-describedby]="'cancel-description'"
                  (clickEvent)="navigateAway()">
              </c11n-button>
          </div>
        </form>
    </div>
</div>
