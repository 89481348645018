import { Component, OnDestroy, OnInit, Renderer2 } from "@angular/core";
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";

import { 
    regexPatterns,
    maxLengths, 
    isInValidator,
    isValidUsZip
} from "../shared/utilities/validators.utility";
import { 
    GRADE_LEVEL_LIST, 
    REFERRAL_LIST,
    CONFERENCE_LIST,
    PARTNERSHIP_LIST,
    SIGNUP_EXTERNAL_LINKS,
    COUNTRY_OPTIONS 
} from "../shared/config/app.constants";
import { InputMask } from "@vg-constellation/angular-15/input";
import { CanadianProvinceData } from "../shared/canadian-provinces-data";
import { UsStateData } from "../shared/us-state-data";
import { ApiService } from "../shared/services/api.service";
import { ModalService } from "../shared/services/modal.service";
import { Router } from "@angular/router";
import { LanguageService } from "../shared/services/language.service";

declare function loadLanguage(): void;

@Component({
    selector: 'app-order-materials',
    templateUrl: './order-materials.component.html',
    styleUrls: ['./order-materials.component.scss']
})
export class OrderMaterialsComponent implements OnInit, OnDestroy {
    // Form
    orderMaterialsForm: FormGroup;

    // Screen size
    isSmallScreen: boolean;

    // Masks
    integerMask = InputMask.INTEGER;
    domesticPhoneNumberMask = InputMask.PHONE;
    zipCodeMask = {
        mask: 'VALUE',
        lazy: true,
        blocks: {
            VALUE: {
                mask: '00000'
            }
        }
    };

    // Constants
    maxLengths = maxLengths;
    references = REFERRAL_LIST;
    conferences = CONFERENCE_LIST;
    partnerships = PARTNERSHIP_LIST;
    signupExternalLinks = SIGNUP_EXTERNAL_LINKS;
    grades = GRADE_LEVEL_LIST;
    countries = COUNTRY_OPTIONS;
    states = UsStateData;
    provinces = CanadianProvinceData;

    // Country options
    unitedStates = 'United States';
    canada = 'Canada';
    other = 'Other';

    // State
    isSubmittedAndInvalid = false;
    isSending = false;
    emailSent = false;

    // Utility
    preserveOriginalOrder = () => 0;  // Stops the keyvalue pipe from sorting results on the key``


    constructor(
        private readonly toast: ToastrService,
        private readonly _apiService: ApiService,
        private readonly breakpointObserver: BreakpointObserver,
        private readonly router: Router,
        private readonly modalService: ModalService,
        private readonly languageService: LanguageService,
        private readonly renderer: Renderer2,
        public readonly fb: FormBuilder
    ) {}

    ngOnInit(): void {
        document.documentElement.classList.add('default-font-size');
        this.languageService.initialize(this.renderer);
        delete this.grades.grade_other;
        this.initForm();
        this.breakpointObserver
            .observe(['(max-width: 768px)'])
            .subscribe((state: BreakpointState) => {
                this.isSmallScreen = state.matches;
        });
    }

    initForm(): void {
        this.orderMaterialsForm = this.fb.group(
            {
                firstName: [
                    '', 
                    Validators.compose([
                        Validators.required,
                        Validators.maxLength(this.maxLengths.firstName),
                        Validators.pattern(regexPatterns.name)
                    ])
                ],
                lastName: [
                    '', 
                    Validators.compose([
                        Validators.required,
                        Validators.maxLength(this.maxLengths.lastName),
                        Validators.pattern(regexPatterns.name)
                    ])
                ],
                email: [
                    '', 
                    Validators.compose([
                        Validators.required,
                        Validators.maxLength(this.maxLengths.email),
                        Validators.pattern(regexPatterns.email)
                    ])
                ],
                schoolName: [
                    '', 
                    Validators.compose([
                        Validators.required,
                        Validators.maxLength(this.maxLengths.schoolName),
                        Validators.pattern(regexPatterns.name)
                    ])
                ],
                gradeKitOrders: this.fb.array([this.createGradeKitFormGroup()]),
                country: [
                    this.unitedStates, 
                    Validators.compose([
                        Validators.required
                    ])
                ],
                shippingAddress: this.fb.group({}),
                phoneNumber: [
                    '', 
                    Validators.compose([
                        Validators.required,
                        Validators.pattern(regexPatterns.domesticPhoneNumber)
                    ])
                ],
                reference: [
                    '', 
                    Validators.compose([
                        Validators.required
                    ]),
                ],
                isTermsAndConditionsAccepted: [
                    false, 
                    Validators.compose([
                        Validators.requiredTrue
                    ])
                ],
                isPrivacyPolicyAccepted: [
                    false, 
                    Validators.compose([
                        Validators.requiredTrue
                    ])
                ],
                captchaResponse: [
                    '',
                    Validators.compose([
                        Validators.required
                    ])
                ]
            }
        );

        this.onCountryChange({value: this.unitedStates});
    }

    createGradeKitFormGroup(): FormGroup {
        return this.fb.group({
            gradeLevel: [
                '', 
                Validators.required
            ],
            numberOfStudents: [
                '', 
                Validators.compose([
                    Validators.required,
                    Validators.min(1),
                    Validators.max(this.maxLengths.numberOfStudents),
                ])
            ],
            language: [
                'English', 
                Validators.required
            ]
        });
    }

    createUsAddressFormGroup(): FormGroup {
        return this.fb.group({
            streetAddress: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(this.maxLengths.streetAddress),
                    Validators.pattern(regexPatterns.name)
                ])
            ],
            city: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(this.maxLengths.city),
                    Validators.pattern(regexPatterns.name)
                ])
            ],
            state: [
                '',
                Validators.compose([
                    Validators.required,
                    isInValidator(this.states.map(state => state.stateCode))
                ])
            ],
            postalCode: [
                '',
                Validators.compose([
                    Validators.required,
                    isValidUsZip()
                ])
            ]
        });
    }

    createCanadaAddressFormGroup(): FormGroup {
        return this.fb.group({
            streetAddress: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(this.maxLengths.streetAddress),
                    Validators.pattern(regexPatterns.name)
                ])
            ],
            city: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(this.maxLengths.city),
                    Validators.pattern(regexPatterns.name)
                ])
            ],
            province: [
                '',
                Validators.compose([
                    Validators.required,
                    isInValidator(this.provinces.map(province => province.canadianProvinceCode))
                ])
            ],
            postalCode: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.pattern(regexPatterns.zipCanada)
                ])
            ]
        });
    }

    createOtherAddressFormGroup(): FormGroup {
        return this.fb.group({
            streetAddress: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(this.maxLengths.streetAddress),
                    Validators.pattern(regexPatterns.name)
                ])
            ]
        });
    }

    get gradeKitOrders() {
        return this.orderMaterialsForm.get('gradeKitOrders') as FormArray;
    }

    get shippingAddress() {
        return this.orderMaterialsForm.get('shippingAddress') as FormGroup;
    }

    onAddRow(): void {
        this.gradeKitOrders.push(this.createGradeKitFormGroup());
    }

    onRemoveRow(rowIndex: number): void {
        this.gradeKitOrders.removeAt(rowIndex);
    }

    onCountryChange(event): void {
        if (event.value === this.unitedStates) {
            this.orderMaterialsForm.setControl('shippingAddress', this.createUsAddressFormGroup());
        } else if (event.value === this.canada) {
            this.orderMaterialsForm.setControl('shippingAddress', this.createCanadaAddressFormGroup());
        } else if (event.value === this.other) {
            this.orderMaterialsForm.setControl('shippingAddress', this.createOtherAddressFormGroup());
        }

        const phoneNumberControl = this.orderMaterialsForm.get('phoneNumber');
        if (event.value === this.unitedStates || event.value === this.canada) {
            phoneNumberControl.setValidators([
                Validators.required,
                Validators.pattern(regexPatterns.domesticPhoneNumber)
            ]);
            phoneNumberControl.updateValueAndValidity();
        } else {
            phoneNumberControl.setValidators([
                Validators.required,
                Validators.pattern(regexPatterns.internationalPhoneNumber)
            ]);
            phoneNumberControl.updateValueAndValidity();
        }
    }

    onReferenceChange(event): void {
        if (event.value === "conference") {
            this.orderMaterialsForm.removeControl('partnership');
            this.orderMaterialsForm.addControl('conference', this.fb.control(
                '',
                Validators.compose([
                    Validators.required
                ])
            ));
        } else if (event.value === "partnership") {
            this.orderMaterialsForm.removeControl('conference');
            this.orderMaterialsForm.addControl('partnership', this.fb.control(
                '',
                Validators.compose([
                    Validators.required
                ])
            ));
        } else {
            this.orderMaterialsForm.removeControl('conference');
            this.orderMaterialsForm.removeControl('partnership');
        }
    }

    showErrorMsg(): void {
        this.languageService.getTranslation(['errorToaster.message', 'errorToaster.title'])
            .subscribe(translations => {
                this.toast.show(
                translations['errorToaster.message'],
                translations['errorToaster.title'],
                { toastClass: 'error' }
                );
            });
    }

    onSubmit(): void {
        this.isSubmittedAndInvalid = this.orderMaterialsForm.invalid;
        if (!this.orderMaterialsForm.invalid) {
            this.isSending = true;
            this._apiService.createPaperMaterial(this.orderMaterialsForm.value).subscribe({
                next: response => {
                    if (response.error) {
                        console.log(response.status, response.error);
                        this.showErrorMsg();
                        this.isSending = false;
                    } else {
                        this.isSending = false;
                        this.router.navigate(['/materials']).then(() => {
                            this.modalService.openThankYouModal();
                            loadLanguage();
                        });
                    }
                },
                error: () => {
                    this.showErrorMsg();
                    this.isSending = false;
                }
            });
        }
    }

    ngOnDestroy(): void {
        document.documentElement.classList.remove('default-font-size');
    }
}
