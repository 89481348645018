<div *ngIf="!emailSent" class="contact-us">
  <div class="contact-us-header center-content">Contact us</div>
  <div class="contact-us-description center-content">
    We are interested in hearing from you as you implement My Classroom Economy. Please submit the form below to share your feedback.
  </div>
  <form [formGroup]="contactUsForm" (ngSubmit)="onSubmit()" class="contact-us-form" focusInvalidInput>
      <div class="form-group">
        <label 
          for="formFieldName" 
          class="form-label inputLabel">
          Name</label>
        <c11n-input 
          [size]="'large'" 
          [hasError]="(contactUsForm.controls['name'].touched || isSubmitted) && contactUsForm.controls['name'].getError(
            'required'
          )">
          <input 
            c11nInput formControlName="name" 
            type="text" 
            class="form-control shadow-none"
            id="formFieldName" 
            autocomplete="off"
            maxlength="{{maxFullNameLength}}" 
            [attr.aria-describedby]="'name-description'"
            [attr.aria-label]="'Name'" />
        </c11n-input>
        <c11n-hint-error 
          [hasError]="(contactUsForm.controls['name'].touched || isSubmitted) && contactUsForm.controls['name'].getError(
            'required'
          )"
          *ngIf="(contactUsForm.controls['name'].touched || isSubmitted) && contactUsForm.controls['name'].getError(
            'required'
          )"
          [hintErrorId]="'name-description'" 
          [errorText]="'Required'"
          [size]="'large'">
        </c11n-hint-error>
      </div>

      <div class="form-group">
        <label 
          for="formFieldEmail" 
          class="form-label inputLabel">
          E-mail Address</label>
        <c11n-input 
          [size]="'large'" 
          [hasError]="(contactUsForm.controls['email'].touched || isSubmitted) && 
            (contactUsForm.controls['email'].getError('required') || contactUsForm.controls['email'].getError('pattern'))">
          <input 
            c11nInput 
            formControlName="email" 
            type="email" 
            class="form-control shadow-none"
            id="formFieldEmail" 
            autocomplete="off" 
            maxlength="{{maxEmailLength}}"
            [attr.aria-describedby]="'email-description'"
            [attr.aria-label]="'E-mail Address'" />
        </c11n-input>
        <c11n-hint-error 
          [hasError]="(contactUsForm.controls['email'].touched || isSubmitted) && 
            contactUsForm.controls['email'].getError('required')"
          *ngIf="(contactUsForm.controls['email'].touched || isSubmitted) && 
            contactUsForm.controls['email'].getError('required')"
          [hintErrorId]="'email-description'"
          [errorText]="'Required'" 
          [size]="'large'">
        </c11n-hint-error>
        <c11n-hint-error 
          [hasError]="(contactUsForm.controls['email'].touched || isSubmitted) && contactUsForm.controls['email'].getError('pattern')"
          *ngIf="(contactUsForm.controls['email'].touched || isSubmitted) && contactUsForm.controls['email'].getError('pattern')"
          [hintErrorId]="'email-description'"
          [errorText]="'Email should be in the format user@domain.com'" 
          [size]="'large'">
        </c11n-hint-error>
      </div>

      <div class="form-group-reason">
        <label 
          for="formFieldReason" 
          class="form-label inputLabel">
          What is the reason you are contacting us today?</label>
        <c11n-radio-group 
          groupId="formFieldReason"
          [hideLegend]="true"
          [hasError]="(contactUsForm.controls['reason'].touched || isSubmitted) && contactUsForm.controls['reason'].getError(
            'required'
          )"
          errorText="Required">
          <div class="storybook-horizontal-radios">
            <div *ngFor="let reason of reasons; let i = index">
              <c11n-radio [labelText]="reason">
                <input 
                  c11nRadioInput 
                  [id]="'formFieldReason' + i" 
                  type="radio" 
                  [value]="i"
                  formControlName="reason"
                  [attr.aria-describedby]="'reason-description-' + i"
                  [attr.aria-label]="reason"/>
              </c11n-radio>
            </div>
          </div>
        </c11n-radio-group>
      </div>

      <div class="form-group-message-subject">
        <c11n-select
          labelText="Message Subject"
          size="large"
          [hideLabel]="false"
          hintText=""
          [hasError]="(contactUsForm.controls['subject'].touched || isSubmitted) && contactUsForm.controls['subject'].getError(
            'required'
          )"
          errorText="Required"
          >
          <select c11nSelectInput formControlName="subject" required>
            <option value="" data-option="placeholder" selected disabled>Select one</option>
            <option 
              *ngFor="let subject of subjectsList" 
              value="{{subject.id}}"
              [attr.aria-describedby]="subject.id"
              [attr.aria-label]="subject.labelText"
              >{{subject.labelText}}</option>
          </select>
        </c11n-select>
      </div>

      <div class="form-group-message">
        <label 
          for="formFieldMessage" 
          class="form-label inputLabel">
          Message</label>
        <c11n-textarea 
          [size]="'large'" 
          [hasError]="(contactUsForm.controls['message'].touched || isSubmitted) && contactUsForm.controls['message'].getError(
            'required'
          )"
          [errorText]="'Required'"
          >
          <textarea 
            c11nTextarea 
            formControlName="message" 
            class="form-control shadow-none message-box"
            id="formFieldMessage" 
            autocomplete="off"
            rows="10"
            maxlength="{{maxMessageLength}}" 
            [attr.aria-describedby]="'message-description'"
            [attr.aria-label]="'Message'"></textarea>
        </c11n-textarea>
        <div class="flex-container">
          <div class="col">
            <c11n-hint-error 
              [hasError]="(contactUsForm.controls['message'].touched || isSubmitted) && contactUsForm.controls['message'].getError(
                'required'
              )" 
              *ngIf="(contactUsForm.controls['message'].touched || isSubmitted) && contactUsForm.controls['message'].getError(
                'required'
              )" 
              [hintErrorId]="'message-description'" 
              [errorText]="'Required'" [size]="'large'">
            </c11n-hint-error>
          </div>
          <div class="col">
            <p class="character-count">{{messageCharCount}}/{{maxMessageLength}}</p>
          </div>
        </div>
      </div>

      <div class="form-group">
        <re-captcha
          formControlName="captchaResponse" 
          [hasError]="true"
          siteKey="6LdApAYqAAAAAOQW_aWW2F_vYOZZU36XKu2dWfaU"
          class="center-captcha">
        </re-captcha>
        <c11n-hint-error
          [hasError]="(contactUsForm.controls['captchaResponse'].touched || isSubmitted) && 
            contactUsForm.controls['captchaResponse'].getError('required')"
          *ngIf="(contactUsForm.controls['captchaResponse'].touched || isSubmitted) && 
            contactUsForm.controls['captchaResponse'].getError('required')"
          [hintErrorId]="'captchaResponse-description'"
          [errorText]="'Valid reCAPTCHA Required'" 
          [size]="'large'">
        </c11n-hint-error>
      </div>

      <div class="form-group center-content">
        <c11n-button
          labelText="Submit"
          buttonType="submit"
          size="large"
          [isWaiting]="isSending"
          [attr.aria-label]="'Submit'"
          [attr.aria-describedby]="'submit-description'">
        </c11n-button>
      </div>
  </form>
</div>
<div *ngIf="emailSent" class="submit-confirmation">
  <div class="submit-icon">
    <img src="assets/images/green_checkmark.svg" alt="Green checkmark">
  </div>
  <div class="submit-confirmation-header">Thank you!</div>
  <div class="submit-confirmation-message">
    Your feedback has been received.
  </div>
</div>