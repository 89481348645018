import { Injectable, Renderer2 } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import Cookies from 'js-cookie';

declare function changeRecaptchaLanguage(language: string) : void;

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    private renderer: Renderer2;

    constructor(
        private readonly translate: TranslateService
    ) {}

    initialize(renderer: Renderer2): void {
        this.renderer = renderer;
    }

    getTranslation(keys: string | string[]): Observable<any> {
        return this.translate.get(keys);
    }

    setLanguage(selectedOption: string): void {
        if (selectedOption !== 'en' && selectedOption !== 'es') {
          return;
        }
        Cookies.set('lang', selectedOption, { expires: 365, sameSite: 'strict' });

        const allEnglish = document.querySelectorAll('.english');
        const allSpanish = document.querySelectorAll('.spanish');

        if (selectedOption === 'en') {
          this.toggleVisibility(allEnglish, false);
          this.toggleVisibility(allSpanish, true);
          this.renderer.setProperty(document.querySelector('#currentLang'), 'textContent', 'EN');
          this.translate.use('en');
          changeRecaptchaLanguage('en');
        } else if (selectedOption === 'es') {
          this.toggleVisibility(allEnglish, true);
          this.toggleVisibility(allSpanish, false);
          this.renderer.setProperty(document.querySelector('#currentLang'), 'textContent', 'ES');
          this.translate.use('es');
          changeRecaptchaLanguage('es');
        }
    }
      
    toggleVisibility(elements: NodeListOf<Element>, hidden: boolean): void {
        elements.forEach(element => {
        if (hidden) {
            this.renderer.addClass(element, 'hidden');
        } else {
            this.renderer.removeClass(element, 'hidden');
        }
        });
    }
}
