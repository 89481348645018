<div class="parallax">

  <div class="gradesHeaderImg container">
    <div class="heroContent english">
      <h1>Materials List</h1>
    </div>
    <div class="heroContent spanish hidden">
      <h1>Materiales para la clase</h1>
    </div>
  </div>
  <section class="backgroundImages" style="top: 0px;">
    <div class="centeredDiv zoom-images">
      <div anchor-target="body" class="heroOverlay"
           data-600-start="background:rgba(0, 0, 0, 0.5)"
           data-start="background:rgba(0, 0, 0, 0)"></div>
      <div anchor-target="body" class="backgroundImage"
           data-600-start="transform: scale(1.3)"
           data-start="transform: scale(1)" id="materialsHeader"></div>
    </div>
  </section>

  <div class="container main" id="skrollr-body">
    <div class="row" style="width: 100%;">
      <div class="col-md-8 gradeContent about">
        <div class="infoBlock english">
          <p>You may download and copy any of these materials for use
            in your classroom. <strong>There is no
              charge to use these materials</strong>. Some items
            can be personalized: On the job offer form,
            for example, you can fill in the blanks onscreen and
            print an individual offer for each of your
            students.</p>
          <p>You may need to turn off your pop-up blocker to open
            these forms.</p>
          <p>Adobe Reader is required to view certain documents
            online. If you don't have it, you can download it
            free of charge from Adobe's website. On the <a
              href="http://www.adobe.com" target="_blank">Adobe
              homepage</a>, you will find a section labeled
            "Download" that contains a link for Adobe Reader
          </p>
          <p>If your school lacks the resources to print or copy
            materials from this site, you may order copies
            from us. Vanguard will mail you individual items you
            choose or the complete kit for the grade you
            teach.<span class="simulator" id="order"><a
              href="\order-materials"
              style="text-decoration:none">Order
                                materials</a></span></p>
        </div>
        <div class="infoBlock spanish hidden">
          <p>Puede descargar y copiar cualquiera de estos materiales
            para usar en su clase. <strong>No hay ningún
              costo por usar estos materiales</strong>. Algunos
            elementos se pueden personalizar: en el
            formulario de oferta de trabajo, por ejemplo, puede
            completar los espacios en blanco e imprimir una
            oferta individual para cada uno de sus alumnos.</p>
          <p>Es posible que deba desactivar su bloqueador de elementos
            emergentes para abrir estos formularios.
          </p>
          <p>Se requiere Adobe Reader para ver ciertos documentos en
            línea. Si no lo tiene, puede descargarlo sin
            costo desde el sitio web de Adobe. <a
              href="http://www.adobe.com" target="_blank">En la
              página de
              inicio de Adobe</a>, encontrará una sección titulada
            "Descargar" que contiene un enlace para
            Adobe Reader.</p>
          <p>Si su escuela no cuenta con los recursos para imprimir o
            copiar materiales de este sitio de la web,
            puede solicitar copias a nosotros. Vanguard le enviará
            por correo los artículos individuales que
            elija o el kit completo para el grado que usted enseñe.<span
              class="simulator" id="order"><a href="\order-materials" style="text-decoration:none">Pedir
                                materiales</a></span></p>
        </div> <!-- spanish end -->
      </div>
      <div class="col-md-4 gradeContent about">
        <!--a href="assets/printables/en/Misc/MyCE_Best_Practices.pdf">
                    <h4>Hi There</h4>
                </a-->
      </div> <!-- column end -->
    </div> <!-- row end -->

    <div class="row" style="width: 100%;">
      <div class="col-md-4 sidebar materials
                materialsContent">
        <div class="sidebarNav">
                    <span class="accordionCloseFull english"
                          data-toggle="collapse" href="#collapse1">
                        <h4 class="about">Elementary School</h4>
                    </span>
          <span class="accordionCloseFull spanish hidden"
                data-toggle="collapse" href="#collapse1">
                        <h4 class="about">Escuela Primaria</h4>
                    </span>
          <ul class="panel-collapse collapse in accordion"
              id="collapse1">
            <li class="active sideNav english" href="#materialsk1">Grades
              K–1
            </li>
            <li class="sideNav english" href="#materials23">Grades
              2–3
            </li>
            <li class="sideNav english" href="#materials45">Grades
              4–5
            </li>
            <li class="active sideNav spanish
                            hidden" href="#materialsk1">Grados K–1
            </li>
            <li class="sideNav spanish hidden" href="#materials23">Grados
              2–3
            </li>
            <li class="sideNav spanish hidden" href="#materials45">Grados
              4–5
            </li>
          </ul>
        </div>
        <div class="sidebarNav">
                    <span class="accordionOpenFull english"
                          data-toggle="collapse" href="#collapse2">
                        <h4 class="about">Middle School</h4>
                    </span>
          <span class="accordionOpenFull spanish hidden"
                data-toggle="collapse" href="#collapse2">
                        <h4 class="about">Secundaria</h4>
                    </span>
          <ul class="panel-collapse collapse accordion"
              id="collapse2">
            <li class="sideNav english" href="#materials6">Grade 6</li>
            <li class="sideNav english" href="#materials78">Grades
              7–8
            </li>
            <li class="sideNav spanish hidden" href="#materials6">Grado
              6
            </li>
            <li class="sideNav spanish hidden" href="#materials78">Grados
              7–8
            </li>
          </ul>
        </div>
        <div class="sidebarNav">
                    <span class="accordionOpenFull english"
                          data-toggle="collapse" href="#collapse3">
                        <h4 class="about">High School</h4>
                    </span>
          <span class="accordionOpenFull spanish hidden"
                data-toggle="collapse" href="#collapse3">
                        <h4 class="about">Bachillerato</h4>
                    </span>
          <ul class="panel-collapse collapse accordion"
              id="collapse3">
            <li class="sideNav english" href="#materials910">Grades
              9–10
            </li>
            <li class="sideNav english" href="#materials1112">Grades
              11–12
            </li>
            <li class="sideNav spanish hidden" href="#materials910">Grados
              9–10
            </li>
            <li class="sideNav spanish
                            hidden" href="#materials1112">Grados 11–12
            </li>
          </ul>
        </div>

        <div class="sidebarNav"><span class="sideNav"
                                      href="#videoMaterials">
                        <h4 class="about english">Introduction videos</h4>
                        <h4 class="about spanish hidden">Videos de Introducción</h4>
                    </span></div>
      </div>

      <div class="col-md-8 gradeContent
                materialsContent" id="materialsTables">

        <!-- GRADES K-1  -->
        <div class="gradesInnerContent" id="materialsk1">
          <h3 class="about k1 english">Grades K–1</h3>
          <div class="infoBlock english">
            <h3>Introductory items</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Program Guide K—1 <sub>(website's
                  program section)</sub></td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/K-1/Program_Guide_K-1.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Letter to administrator</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/K-1/Letter_Administrator_K-1.docx')"><img
                  src="assets/images/doc_link.svg"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Items</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>$1 Bills</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/K-1/Bills_1_K-1.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Job Posters</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/K-1/Job_Posters_K-1.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Saver certificate</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/K-1/Saver_Cert_K-1.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Supplies to have on hand</h3>
            <table class="table">
              <tbody>
              <tr>
                <td>Envelopes</td>
              </tr>
              <tr>
                <td>Portfolio folders</td>
              </tr>
              <tr>
                <td>Cash box or envelope</td>
              </tr>
              <tr>
                <td>Items for store</td>
              </tr>
              </tbody>
            </table>
          </div>
          <h3 class="about k1 spanish hidden">Grados K–1</h3>
          <div class="infoBlock spanish hidden">
            <h3>Elementos introductorios</h3>
            <table class="table">
              <tbody>
              <tr>
                <td>Guía del programa K—1 <sub>(sección del
                  programa de la página web)</sub></td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/K-1/Program_Guide_K-1_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Carta para los administradores</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/K-1/Letter_Administrator_K-1_ES.docx')"><img
                  src="assets/images/doc_link.svg"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Items</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Billetes de $1</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/K-1/Bills_1_K-1_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Afiches de trabajo</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/K-1/Job_Posters_K-1_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Certificado de ahorro</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/K-1/Saver_Cert_K-1_ES.pdf')">
                  <img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Suministros para tener al alcance</h3>
            <table
              class="table">

              <tbody>
              <tr>
                <td>Sobres</td>
              </tr>
              <tr>
                <td>Carpetas de portafolios</td>
              </tr>
              <tr>
                <td>Caja de dinero</td>
              </tr>
              <tr>
                <td>Artículos para la tienda</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- GRADES 2-3  -->
        <div class="gradesInnerContent hidden" id="materials23">
          <h3 class="about k1 english">Grades 2–3</h3>
          <div class="infoBlock english">
            <h3>Introductory items</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Program Guide 2—3 <sub>(website's
                  program section)</sub></td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/2-3/Program_Guide_2-3.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Letter to parents</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/2-3/Letter_Parents_2-3.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Letter to administrator</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/2-3/Letter_Administrator_2-3.docx')"><img
                  src="assets/images/doc_link.svg"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Employment</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Payday video</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/CE_S01.mp4')"><img
                  src="assets/images/video_icon.svg"></a></td>
              </tr>
              <tr>
                <td>Job application</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/2-3/Job_Application_2-3.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Job posters</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/2-3/Job_Posters_2-3.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Paycheck</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/2-3/Paycheck_2-3.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Banking</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Bank log</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/2-3/Bank_Log_2-3.pdf')"><img
                  src="assets/images/pdf_icon.png"></a>
                </td>
              </tr>
              </tbody>
            </table>

            <h3>Fines &amp; tickets</h3>
            <table class="table">

              
              <tbody>
              <tr>
                <td>Fine tickets</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/2-3/Fine_Ticket_2-3.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Offense log</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/2-3/Offense_Log_2-3.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Cash</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>$5 bills</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/2-3/Bills_5_2-3.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>$10 bills</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/2-3/Bills_10_2-3.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>$20 bills</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/2-3/Bills_20_2-3.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>$50 bills</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/2-3/Bills_50_2-3.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Rent &amp; deeds</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Unpaid rent notice</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/2-3/Unpaid_Rent_Notice_2-3.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Deed to desk</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/2-3/Desk_Deed_2-3.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Auctions</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Auction video</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/CE_S04.mp4')"><img
                  src="assets/images/video_icon.svg"></a></td>
              </tr>
              <tr>
                <td>Auction record form</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/2-3/Auction_Record_2-3.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Auction paddle</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/2-3/Auction_Paddle_2-3.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Year-end certificates</h3>
            <table class="table">
              <tbody>
              <tr>
                <td>Saver certificates (Includes Saver,
                  Super, and Ultimate awards)
                </td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/2-3/Saver_Certs_2-3.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Posters &amp; handouts</h3>
            <table class="table">
              <tbody>
              <tr>
                <td>Bonus list</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/2-3/Bonus_List_2-3.docx')"><img
                  src="assets/images/doc_link.svg"></a></td>
              </tr>
              <tr>
                <td>Fine list</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/2-3/Fine_List_2-3.docx')"><img
                  src="assets/images/doc_link.svg"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Supplies to have on hand</h3>
            <table class="table">
              <tbody>
              <tr>
                <td>Envelopes</td>
              </tr>
              <tr>
                <td>Portfolio folders</td>
              </tr>
              <tr>
                <td>Cash drawer or box</td>
              </tr>
              <tr>
                <td>Popsicle sticks for auction paddles</td>
              </tr>
              <tr>
                <td>Sticky notes</td>
              </tr>
              <tr>
                <td>Clipboard</td>
              </tr>
              <tr>
                <td>Auction items</td>
              </tr>
              </tbody>
            </table>
          </div>

          <h3 class="about k1 spanish hidden">Grados 2–3</h3>
          <div class="infoBlock spanish hidden">
            <h3>Elementos introductorios</h3>
            <table class="table">
              <tbody>
              <tr>
                <td>Guía del programa 2—3 <sub>(sección del
                  programa de la página web)</sub></td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/2-3/Program_Guide_2-3_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Carta para los padres</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/2-3/Letter_Parents_2-3_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Carta para los administradores</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/2-3/Letter_Administrator_2-3_ES.docx')"><img
                  src="assets/images/doc_link.svg"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Empleo</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Video sobre el día de pago</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/CE_S01.mp4')"><img
                  src="assets/images/video_icon.svg"></a></td>
              </tr>
              <tr>
                <td>Aplicación de trabajo</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/2-3/Job_Application_2-3_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Afiches de trabajo</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/2-3/Job_Posters_2-3_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Sueldo</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/2-3/Paycheck_2-3_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Bancario/a</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Registro bancario</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/2-3/Bank_Log_2-3_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a>
                </td>
              </tr>
              </tbody>
            </table>

            <h3>Multas</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Tiquetes de multa</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/2-3/Fine_Ticket_2-3_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Registro de ofensas</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/2-3/Offense_Log_2-3_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Dinero</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Billetes de $5</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/2-3/Bills_5_2-3_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Billetes de $10</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/2-3/Bills_10_2-3_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Billetes de $20</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/2-3/Bills_20_2-3_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Billetes de $50</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/2-3/Bills_50_2-3_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Alquiler y título de propiedad</h3>
            <table
              class="table">

              <tbody>
              <tr>
                <td>Aviso de renta no pagada</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/2-3/Unpaid_Rent_Notice_2-3_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Título de propiedad del escritorio</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/2-3/Desk_Deed_2-3_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Subastas</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Video sobre subastas</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/CE_S04.mp4')"><img
                  src="assets/images/video_icon.svg"></a></td>
              </tr>
              <tr>
                <td>Formulario de registro de subasta</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/2-3/Auction_Record_2-3_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Paleta de subastas</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/2-3/Auction_Paddle_2-3_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Certificados de fin de año</h3>
            <table class="table">
              <tbody>
              <tr>
                <td>Certificado de ahorro (Incluye los
                  premios Saver, Super y Ultimate)
                </td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/2-3/Saver_Certs_2-3_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Afiches y folletos</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Lista de bonificaciones</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/2-3/Bonus_List_2-3_ES.doc')"><img
                  src="assets/images/doc_link.svg"></a></td>
              </tr>
              <tr>
                <td>Lista de multas</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/2-3/Fine_List_2-3_ES.doc')"><img
                  src="assets/images/doc_link.svg"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Suministros para tener al alcance</h3>
            <table
              class="table">

              <tbody>
              <tr>
                <td>Sobres</td>
              </tr>
              <tr>
                <td>Carpetas de portafolios</td>
              </tr>
              <tr>
                <td>Caja de dinero</td>
              </tr>
              <tr>
                <td>Palos de paleta para las paletas de
                  subasta
                </td>
              </tr>
              <tr>
                <td>Notas adhesivas</td>
              </tr>
              <tr>
                <td>Portapapeles</td>
              </tr>
              <tr>
                <td>Artículos de subasta</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- GRADES 4-5 -->
        <div class="gradesInnerContent hidden" id="materials45">
          <h3 class="about k1 english">Grades 4–5</h3>
          <div class="infoBlock english">
            <h3>Introductory items</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Program Guide 4—5 <sub>(website's
                  program section)</sub></td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/4-5/Program_Guide_4-5.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Letter to parents</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/4-5/Letter_Parents_4-5.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Letter to administrator</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/4-5/Letter_Administrator_4-5.docx')"><img
                  src="assets/images/doc_link.svg"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Employment</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Payday video</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/CE_S01.mp4')"><img
                  src="assets/images/video_icon.svg"></a></td>
              </tr>
              <tr>
                <td>Job application</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/4-5/Job_Application_4-5.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Job offer</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/4-5/Job_Offer_4-5.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Job posters</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/4-5/Job_Posters_4-5.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Paycheck</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/4-5/Paycheck_4-5.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Banking</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Bank log</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/4-5/Bank_Log_4-5.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Bank slips</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/4-5/Bank_Slip_4-5.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Fines</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Fine tickets</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/4-5/Fine_Ticket_4-5.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Offense log</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/4-5/Offense_Log_4-5.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Cash</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>$50 bills <sub>(small bills: see grades
                  2—3)</sub></td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/4-5/Bills_50_4-5.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>$100 bills</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/4-5/Bills_100_4-5.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>$500 bills</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/4-5/Bills_500_4-5.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Rent &amp; deeds</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Rental agreement</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/4-5/Rental_Agreement_4-5.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Rent log</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/4-5/Rent_Log_4-5.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Unpaid rent notice</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/4-5/Unpaid_Rent_Notice_4-5.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Deed to desk</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/4-5/Desk_Deed_4-5.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Auctions</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Auction video</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/CE_S04.mp4')"><img
                  src="assets/images/video_icon.svg"></a></td>
              </tr>
              <tr>
                <td>Auction record form</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/4-5/Auction_Record_4-5.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Auction paddle</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/4-5/Auction_Paddle_4-5.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Year-end certificates</h3>
            <table class="table">
              <tbody>
              <tr>
                <td>Saver certificates (Includes Saver,
                  Super, and Ultimate awards)
                </td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/4-5/Saver_Certs_4-5.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Posters &amp; handouts</h3>
            <table class="table">
              <tbody>
              <tr>
                <td>Bonus list</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/4-5/Bonus_List_4-5.docx')"><img
                  src="assets/images/doc_link.svg"></a></td>
              </tr>
              <tr>
                <td>Fine list</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/4-5/Fine_List_4-5.docx')"><img
                  src="assets/images/doc_link.svg"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Supplies to have on hand</h3>
            <table class="table">
              <tbody>
              <tr>
                <td>Envelopes</td>
              </tr>
              <tr>
                <td>Portfolio folders</td>
              </tr>
              <tr>
                <td>Cash drawer or box</td>
              </tr>
              <tr>
                <td>Popsicle sticks for auction paddles</td>
              </tr>
              <tr>
                <td>Sticky notes</td>
              </tr>
              <tr>
                <td>Clipboard</td>
              </tr>
              <tr>
                <td>Auction items</td>
              </tr>
              </tbody>
            </table>
          </div>

          <h3 class="about k1 spanish hidden">Grados 4–5</h3>
          <div class="infoBlock spanish hidden">
            <h3>Elementos introductorios</h3>
            <table class="table">
              <tbody>
              <tr>
                <td>Guía del programa 4-5 <sub>(sección del
                  programa de la página web)</sub></td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/4-5/Program_Guide_4-5_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Carta para los padres</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/4-5/Letter_Parents_4-5_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Carta para los administradores</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/4-5/Letter_Administrator_4-5_ES.docx')"><img
                  src="assets/images/doc_link.svg"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Empleo</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Video sobre el día de pago</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/CE_S01.mp4')"><img
                  src="assets/images/video_icon.svg"></a></td>
              </tr>
              <tr>
                <td>Aplicación de trabajo</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/4-5/Job_Application_4-5_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Oferta de trabajo</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/4-5/Job_Offer_4-5_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Afiches de trabajo</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/4-5/Job_Posters_4-5_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Sueldo</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/4-5/Paycheck_4-5_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Bancario/a</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Registro bancario</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/4-5/Bank_Log_4-5_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Recibos bancarios</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/4-5/Bank_Slip_4-5_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Multas</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Tiquetes de multa</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/4-5/Fine_Ticket_4-5_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Registro de ofensas</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/4-5/Offense_Log_4-5_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Dinero</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Billetes de $50 <sub>(facturas pequeñas:
                  refiera los grados 2-3)</sub></td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/4-5/Bills_50_4-5_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Billetes de $100</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/4-5/Bills_100_4-5_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Billetes de $500</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/4-5/Bills_500_4-5_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Alquiler y título de propiedad</h3>
            <table
              class="table">

              <tbody>
              <tr>
                <td>Contrato de alquiler</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/4-5/Rental_Agreement_4-5_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Registro de alquiler</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/4-5/Rent_Log_4-5_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Aviso de renta no pagada</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/4-5/Unpaid_Rent_Notice_4-5_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Título de propiedad del escritorio</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/4-5/Desk_Deed_4-5_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Subastas</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Video sobre subastas</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/CE_S04.mp4')"><img
                  src="assets/images/video_icon.svg"></a></td>
              </tr>
              <tr>
                <td>Formulario de registro de subasta</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/4-5/Auction_Record_4-5_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Paleta de subastas</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/4-5/Auction_Paddle_4-5_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Certificados de fin de año </h3>
            <table
              class="table">

              <tbody>
              <tr>
                <td>Certificado de ahorro (Incluye los
                  premios Saver, Super y Ultimate)
                </td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/4-5/Saver_Certs_4-5_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Afiches y folletos</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Lista de bonificaciones</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/4-5/Bonus_List_4-5_ES.doc')"><img
                  src="assets/images/doc_link.svg"></a></td>
              </tr>
              <tr>
                <td>Lista de multas</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/4-5/Fine_List_4-5_ES.doc')"><img
                  src="assets/images/doc_link.svg"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Suministros para tener al alcance</h3>
            <table
              class="table">

              <tbody>
              <tr>
                <td>Sobres</td>
              </tr>
              <tr>
                <td>Carpetas de portafolios</td>
              </tr>
              <tr>
                <td>Caja de dinero</td>
              </tr>
              <tr>
                <td>Palos de paleta para las paletas de
                  subasta
                </td>
              </tr>
              <tr>
                <td>Notas adhesivas</td>
              </tr>
              <tr>
                <td>Portapapeles</td>
              </tr>
              <tr>
                <td>Artículos de subasta</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- GRADES 6 -->
        <div class="gradesInnerContent hidden" id="materials6">
          <h3 class="about english">Grade 6</h3>
          <div class="infoBlock english">
            <h3>Introductory items</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Program Guide 6 <sub>(website's program
                  section)</sub></td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/6/Program_Guide_6.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Letter to parents</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/6/Letter_Parents_6.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Letter to administrator</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/6/Letter_Administrator_6.docx')"><img
                  src="assets/images/doc_link.svg"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Employment</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Payday video</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/CE_S01.mp4')"><img
                  src="assets/images/video_icon.svg"></a></td>
              </tr>
              <tr>
                <td>Job application</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/6/Job_Application_6.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Job offer</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/6/Job_Offer_6.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Job posters</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/6/Job_Posters_6.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Paychecks</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/6/Paycheck_6.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Banking</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Bank log</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/6/Bank_Log_6.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Bank slips</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/6/Bank_Slip_6.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Fines</h3>
            <table class="table">

              
              <tbody>
              <tr>
                <td>Fine tickets</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/6/Fine_Ticket_6.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Offense log</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/6/Offense_Log_6.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Cash</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>$50 bills <sub>(small bills: see grades
                  2—3)</sub></td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/6/Bills_50_6.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>$100 bills</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/6/Bills_100_6.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>$500 bills</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/6/Bills_500_6.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Rent &amp; deeds</h3>
            <table class="table">

              
              <tbody>
              <tr>
                <td>Rental agreement</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/6/Rental_Agreement_6.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Rent log</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/6/Rent_Log_6.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Unpaid rent notice</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/6/Unpaid_Rent_Notice_6.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Deed to desk</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/6/Desk_Deed_6.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Auctions</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Auction video</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/CE_S04.mp4')"><img
                  src="assets/images/video_icon.svg"></a></td>
              </tr>
              <tr>
                <td>Auction record form</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/6/Auction_Record_6.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Auction paddle</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/6/Auction_Paddle_6.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Year-end certificates</h3>
            <table class="table">
              <tbody>
              <tr>
                <td>Saver certificates (Includes Saver,
                  Super, and Ultimate awards)
                </td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/6/Saver_Certs_6.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Posters &amp; handouts</h3>
            <table class="table">
              <tbody>
              <tr>
                <td>Bonus list</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/6/Bonus_List_6.docx')"><img
                  src="assets/images/doc_link.svg"></a></td>
              </tr>
              <tr>
                <td>Fine list</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/6/Fine_List_6.docx')"><img
                  src="assets/images/doc_link.svg"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Supplies to have on hand</h3>
            <table class="table">
              <tbody>
              <tr>
                <td>Envelopes</td>
              </tr>
              <tr>
                <td>Portfolio folders</td>
              </tr>
              <tr>
                <td>Cash drawer or box</td>
              </tr>
              <tr>
                <td>Popsicle sticks for auction paddles</td>
              </tr>
              <tr>
                <td>Sticky notes</td>
              </tr>
              <tr>
                <td>Clipboard</td>
              </tr>
              <tr>
                <td>Auction items</td>
              </tr>
              </tbody>
            </table>
          </div>

          <h3 class="about spanish hidden">Grado 6</h3>
          <div class="infoBlock spanish hidden">
            <h3>Elementos introductorios</h3>
            <table class="table">
              <tbody>
              <tr>
                <td>Guía del programa 6 <sub>(sección del
                  programa de la página web)</sub></td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/6/Program_Guide_6_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Carta para los padres</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/6/Letter_Parents_6_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Carta para los administradores</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/6/Letter_Administrator_6_ES.docx')"><img
                  src="assets/images/doc_link.svg"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Empleo</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Video sobre el día de pago</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/CE_S01.mp4')"><img
                  src="assets/images/video_icon.svg"></a></td>
              </tr>
              <tr>
                <td>Aplicación de trabajo</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/6/Job_Application_6_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Oferta de trabajo</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/6/Job_Offer_6_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Afiches de trabajo</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/6/Job_Posters_6_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Sueldo</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/6/Paycheck_6_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Bancario/a</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Registro bancario</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/6/Bank_Log_6_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Recibos bancarios</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/6/Bank_Slip_6_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Multas</h3>
            <table class="table">

              
              <tbody>
              <tr>
                <td>Tiquetes de multa</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/6/Fine_Ticket_6_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Registro de ofensas</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/6/Offense_Log_6_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Dinero</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Billetes de $50 <sub>(facturas pequeñas:
                  refiera los grados 2-3)</sub></td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/6/Bills_50_6_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Billetes de $100</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/6/Bills_100_6_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Billetes de $500</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/6/Bills_500_6_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Alquiler y título de propiedad</h3>
            <table
              class="table">

              
              <tbody>
              <tr>
                <td>Contrato de alquiler</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/6/Rental_Agreement_6_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Registro de alquiler</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/6/Rent_Log_6_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Aviso de renta no pagada</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/6/Unpaid_Rent_Notice_6_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Título de propiedad del escritorio</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/6/Desk_Deed_6_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Subastas</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Video sobre subastas</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/CE_S04.mp4')"><img
                  src="assets/images/video_icon.svg"></a></td>
              </tr>
              <tr>
                <td>Formulario de registro de subasta</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/6/Auction_Record_6_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Paleta de subastas</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/6/Auction_Paddle_6_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Certificados de fin de año</h3>
            <table class="table">
              <tbody>
              <tr>
                <td>Certificado de ahorro (Incluye los
                  premios Saver, Super y Ultimate)
                </td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/6/Saver_Certs_6_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Afiches y folletos</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Lista de bonificaciones</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/6/Bonus_List_6_ES.doc')"><img
                  src="assets/images/doc_link.svg"></a></td>
              </tr>
              <tr>
                <td>Lista de multas</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/6/Fine_List_6_ES.doc')"><img
                  src="assets/images/doc_link.svg"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Suministros para tener al alcance</h3>
            <table
              class="table">

              <tbody>
              <tr>
                <td>Sobres</td>
              </tr>
              <tr>
                <td>Carpetas de portafolios</td>
              </tr>
              <tr>
                <td>Caja de dinero</td>
              </tr>
              <tr>
                <td>Palos de paleta para las paletas de
                  subasta
                </td>
              </tr>
              <tr>
                <td>Notas adhesivas</td>
              </tr>
              <tr>
                <td>Portapapeles</td>
              </tr>
              <tr>
                <td>Artículos de subasta</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- GRADES 7-8 -->
        <div class="gradesInnerContent hidden" id="materials78">
          <h3 class="about english">Grades 7–8</h3>
          <div class="infoBlock english">
            <h3>Introductory items</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Program Guide 7—8 <sub>(website's
                  program section)</sub></td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/7-8/Program_Guide_7-8.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Letter to parents</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/7-8/Letter_Parents_7-8.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Letter to administrator</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/7-8/Letter_Administrator_7-8.docx')"><img
                  src="assets/images/doc_link.svg"></a></td>
              </tr>
              <tr>
                <td>Donation request letter</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/7-8/Letter_Donation_Request_7-8.docx')"><img
                  src="assets/images/doc_link.svg"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Employment</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Payday video</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/CE_S01.mp4')"><img
                  src="assets/images/video_icon.svg"></a></td>
              </tr>
              <tr>
                <td>Job application</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/7-8/Job_Application_7-8.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Job offer</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/7-8/Job_Offer_7-8.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Job posters</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/7-8/Job_Posters_7-8.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Paychecks</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/7-8/Paycheck_7-8.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Business license</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/7-8/Business_License_7-8.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Banking</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Bank slips</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/7-8/Bank_Slip_7-8.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Classroom bank log</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/7-8/Bank_Log_7-8.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Fines</h3>
            <table class="table">

              
              <tbody>
              <tr>
                <td>Fine tickets</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/7-8/Fine_Ticket_7-8.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Offense log</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/7-8/Offense_Log_7-8.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Cash</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>$50 bills <sub>(small bills: see grades
                  2—3)</sub></td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/7-8/Bills_50_7-8.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>$100 bills</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/7-8/Bills_100_7-8.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>$500 bills</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/7-8/Bills_500_7-8.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Rent &amp; deeds</h3>
            <table class="table">

              
              <tbody>
              <tr>
                <td>Rental agreement</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/7-8/Rental_Agreement_7-8.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Rent log</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/7-8/Rent_Log_7-8.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Unpaid rent notice</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/7-8/Unpaid_Rent_Notice_7-8.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Deed to desk</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/7-8/Desk_Deed_7-8.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Bills &amp; taxes</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Classroom bill pay log</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/7-8/Bill_Pay_Log_7-8.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Classroom energy log</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/7-8/Energy_Log_7-8.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Tax record form</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/7-8/Tax_Record_7-8.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Auctions</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Auction video</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/CE_S04.mp4')"><img
                  src="assets/images/video_icon.svg"></a></td>
              </tr>
              <tr>
                <td>Auction record form</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/7-8/Auction_Record_7-8.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Auction paddle</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/7-8/Auction_Paddle_7-8.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Year-end certificates</h3>
            <table class="table">
              <tbody>
              <tr>
                <td>Saver certificates (Includes Saver,
                  Super, and Ultimate awards)
                </td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/7-8/Saver_Certs_7-8.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Posters &amp; handouts</h3>
            <table class="table">
              <tbody>
              <tr>
                <td>Bonus list</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/7-8/Bonus_List_7-8.docx')"><img
                  src="assets/images/doc_link.svg"></a></td>
              </tr>
              <tr>
                <td>Fine list</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/7-8/Fine_List_7-8.docx')"><img
                  src="assets/images/doc_link.svg"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Supplies to have on hand</h3>
            <table class="table">
              <tbody>
              <tr>
                <td>Envelopes</td>
              </tr>
              <tr>
                <td>Portfolio folders</td>
              </tr>
              <tr>
                <td>Cash drawer or box</td>
              </tr>
              <tr>
                <td>Popsicle sticks for auction paddles</td>
              </tr>
              <tr>
                <td>Sticky notes</td>
              </tr>
              <tr>
                <td>Clipboard</td>
              </tr>
              <tr>
                <td>Auction items</td>
              </tr>
              </tbody>
            </table>
          </div>

          <h3 class="about spanish hidden">Grados 7–8</h3>
          <div class="infoBlock spanish hidden">
            <h3>Elementos introductorios</h3>
            <table class="table">
              <tbody>
              <tr>
                <td>Guía del programa 7—8 <sub>(sección del
                  programa de la página de web)</sub></td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/7-8/Program_Guide_7-8_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Carta para los padres</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/7-8/Letter_Parents_7-8_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Carta para los administradores</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/7-8/Letter_Administrator_7-8_ES.docx')"><img
                  src="assets/images/doc_link.svg"></a></td>
              </tr>
              <tr>
                <td>Carta de solicitud para donaciones</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/7-8/Letter_Donation_Request_7-8_ES.docx')"><img
                  src="assets/images/doc_link.svg"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Empleo</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Video sobre el día de pago</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/CE_S01.mp4')"><img
                  src="assets/images/video_icon.svg"></a></td>
              </tr>
              <tr>
                <td>Aplicación de trabajo</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/7-8/Job_Application_7-8_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Oferta de trabajo</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/7-8/Job_Offer_7-8_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Afiches de trabajo</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/7-8/Job_Posters_7-8_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Sueldo</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/7-8/Paycheck_7-8_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Licencia de negocios</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/7-8/Business_License_7-8_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Bancario/a</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Recibos bancarios</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/7-8/Bank_Slip_7-8_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>El registro de banco para la clase</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/7-8/Bank_Log_7-8_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Multas</h3>
            <table class="table">

              
              <tbody>
              <tr>
                <td>Tiquetes de multa</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/7-8/Fine_Ticket_7-8_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Registro de ofensas</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/7-8/Offense_Log_7-8_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Dinero</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Billetes de $50 <sub>(facturas pequeñas:
                  refiera los grados 2-3)</sub></td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/7-8/Bills_50_7-8_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Billetes de $100</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/7-8/Bills_100_7-8_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Billetes de $500</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/7-8/Bills_500_7-8_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Alquiler y título de propiedad</h3>
            <table
              class="table">

              
              <tbody>
              <tr>
                <td>Contrato de alquiler</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/7-8/Rental_Agreement_7-8_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Registro de alquiler</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/7-8/Rent_Log_7-8_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Aviso de renta no pagada</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/7-8/Unpaid_Rent_Notice_7-8_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Título de propiedad del escritorio</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/7-8/Desk_Deed_7-8_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Cuentas e impuestos</h3>
            <table class="table">
              <tbody>
              <tr>
                <td>Registro de cuentas pagadas de la clase</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/7-8/Bill_Pay_Log_7-8_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Registro de energía de la clase</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/7-8/Energy_Log_7-8_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Documento de registro de impuestos</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/7-8/Tax_Record_7-8_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Subastas</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Video sobre subastas</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/CE_S04.mp4')"><img
                  src="assets/images/video_icon.svg"></a></td>
              </tr>
              <tr>
                <td>Forma de registro de subasta</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/7-8/Auction_Record_7-8_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Paleta de subastas</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/7-8/Auction_Paddle_7-8_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Certificados de fin de año</h3>
            <table class="table">
              <tbody>
              <tr>
                <td>Certificado de ahorro (Incluye los
                  premios Saver, Super y Ultimate)
                </td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/7-8/Saver_Certs_7-8_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Afiches y folletos</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Lista de bonificaciones</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/7-8/Bonus_List_7-8_ES.doc')"><img
                  src="assets/images/doc_link.svg"></a></td>
              </tr>
              <tr>
                <td>Lista de multas</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/7-8/Fine_List_7-8_ES.doc')"><img
                  src="assets/images/doc_link.svg"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Suministros para tener al alcance</h3>
            <table
              class="table">

              <tbody>
              <tr>
                <td>Sobres</td>
              </tr>
              <tr>
                <td>Carpetas de portafolios</td>
              </tr>
              <tr>
                <td>Caja de dinero</td>
              </tr>
              <tr>
                <td>Palos de paleta para las paletas de
                  subasta
                </td>
              </tr>
              <tr>
                <td>Notas adhesivas</td>
              </tr>
              <tr>
                <td>Portapapeles</td>
              </tr>
              <tr>
                <td>Artículos de subasta</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- GRADES 9-10 -->
        <div class="gradesInnerContent hidden" id="materials910">
          <h3 class="about nineten english">Grades 9–10</h3>
          <div class="infoBlock english">
            <h3>Introductory items</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Program Guide 9—10 <sub>(website's
                  program section)</sub></td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/9-10/Program_Guide_9-10.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Letter to parents</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/9-10/Letter_Parents_9-10.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Letter to administrator</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/9-10/Letter_Administrator_9-10.docx')"><img
                  src="assets/images/doc_link.svg"></a></td>
              </tr>
              <tr>
                <td>Syllabus</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/9-10/Syllabus_9-10.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Employment</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Job application</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/9-10/Job_Application_9-10.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Job offer</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/9-10/Job_Offer_9-10.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Business license</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/9-10/Business_License_9-10.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Auditor checklist</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/9-10/Auditor_Checklist_9-10.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Banking &amp; bills</h3>
            <table class="table">
              <tbody>
              <tr>
                <td>Bank log</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/9-10/Bank_Log_9-10.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Unpaid bill notice</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/9-10/Unpaid_Bill_9-10.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Bill payment log</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/9-10/Bill_Pay_Log_9-10.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Tax record form</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/9-10/Tax_Record_9-10.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Classroom energy log</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/9-10/Energy_Log_9-10.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Bonuses &amp; fines</h3>
            <table class="table">
              <tbody>
              <tr>
                <td>Bonus/fine tickets</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/9-10/Bonus-Fine_Tickets_9-10.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Bonus/fine log</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/9-10/Bonus-Fine_Log_9-10.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Rent &amp; deeds</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Rental agreement</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/9-10/Rental_Agreement_9-10.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Deed to desk</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/9-10/Desk_Deed_9-10.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Personal finance</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Investment log</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/9-10/Investment_Log_9-10.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Insurance log</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/9-10/Insurance_Log_9-10.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Auctions</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Auction record form</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/9-10/Auction_Record_9-10.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Donation request letter</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/9-10/Letter_Donation_Request_9-10.docx')"><img
                  src="assets/images/doc_link.svg"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Year-end certificates</h3>
            <table class="table">
              <tbody>
              <tr>
                <td>Certificate of achievement</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/9-10/Achievement_Cert_9-10.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Supplies to have on hand</h3>
            <table class="table">
              <tbody>
              <tr>
                <td>Portfolio folders</td>
              </tr>
              <tr>
                <td>Shoebox</td>
              </tr>
              <tr>
                <td>Auction items</td>
              </tr>
              </tbody>
            </table>
          </div>

          <h3 class="about nineten spanish hidden">Grados 9–10</h3>
          <div class="infoBlock spanish hidden">
            <h3>Elementos introductorios</h3>
            <table class="table">
              <tbody>
              <tr>
                <td>Guía del programa 9—10 <sub>(sección del
                  programa de la página de web)</sub></td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/9-10/Program_Guide_9-10_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Carta para los padres</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/9-10/Letter_Parents_9-10_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Carta para los administradores</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/9-10/Letter_Administrator_9-10_ES.docx')"><img
                  src="assets/images/doc_link.svg"></a></td>
              </tr>
              <tr>
                <td>Silaba</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/9-10/Syllabus_9-10_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Empleo</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Aplicación de trabajo</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/9-10/Job_Application_9-10_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Oferta de trabajo</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/9-10/Job_Offer_9-10_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Licencia de negocios</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/9-10/Business_License_9-10_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Lista de verificación del auditor</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/9-10/Auditor_Checklist_9-10_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Bancario/a</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Recibos bancarios</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/9-10/Bank_Log_9-10_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Aviso de factura sin pagar</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/9-10/Unpaid_Bill_9-10_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Registro de pago de facturas</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/9-10/Bill_Pay_Log_9-10_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Formulario de registro de impuestos</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/9-10/Tax_Record_9-10_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Registro de energía del aula</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/9-10/Energy_Log_9-10_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Bonificaciones y multas</h3>
            <table class="table">
              <tbody>
              <tr>
                <td>Boletos de bonificación/multa</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/9-10/Bonus-Fine_Tickets_9-10_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Registro de bonificación/multa</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/9-10/Bonus-Fine_Log_9-10_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Alquiler y título de propiedad</h3>
            <table
              class="table">

              <tbody>
              <tr>
                <td>Contrato de alquiler</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/9-10/Rental_Agreement_9-10_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Título de propiedad del escritorio</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/9-10/Desk_Deed_9-10_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Finanzas personales</h3>
            <table class="table">
              <tbody>
              <tr>
                <td>Registro de inversiones</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/9-10/Investment_Log_9-10_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Registro de seguros</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/9-10/Insurance_Log_9-10_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Subastas</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Forma de registro de subasta</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/9-10/Auction_Record_9-10_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Carta de solicitud de donación</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/9-10/Letter_Donation_Request_9-10_ES.docx')"><img
                  src="assets/images/doc_link.svg"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Certificados de fin de año</h3>
            <table class="table">
              <tbody>
              <tr>
                <td>Certificado de logro</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/9-10/Achievement_Cert_9-10_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Suministros para tener al alcance</h3>
            <table
              class="table">

              <tbody>
              <tr>
                <td>Carpetas de portafolios</td>
              </tr>
              <tr>
                <td>Shoebox</td>
              </tr>
              <tr>
                <td>Artículos de subasta</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- GRADES 11-12 -->
        <div class="gradesInnerContent hidden" id="materials1112">
          <h3 class="about nineten english">Grades 11–12</h3>
          <div class="infoBlock english">
            <h3>Introductory items</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Program Guide 11—12 <sub>(website's
                  program section)</sub></td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/11-12/Program_Guide_11-12.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Letter to parents</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/11-12/Letter_Parents_11-12.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Letter to administrator</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/11-12/Letter_Administrator_11-12.docx')"><img
                  src="assets/images/doc_link.svg"></a></td>
              </tr>
              <tr>
                <td>Syllabus</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/11-12/Syllabus_11-12.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Employment</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Job application</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/11-12/Job_Application_11-12.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Job offer</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/11-12/Job_Offer_11-12.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Business license</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/11-12/Business_License_11-12.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Auditor checklist</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/11-12/Auditor_Checklist_11-12.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Banking &amp; bills</h3>
            <table class="table">
              <tbody>
              <tr>
                <td>Bank log</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/11-12/Bank_Log_11-12.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Unpaid bill notice</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/11-12/Unpaid_Bill_11-12.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Bill payment log</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/11-12/Bill_Pay_Log_11-12.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Tax record form</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/11-12/Tax_Record_11-12.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Classroom energy log</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/11-12/Energy_Log_11-12.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Bonuses &amp; fines</h3>
            <table class="table">
              <tbody>
              <tr>
                <td>Bonus/fine tickets</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/11-12/Bonus-Fine_Tickets_11-12.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Bonus/fine log</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/11-12/Bonus-Fine_Log_11-12.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Rent &amp; deeds</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Rental agreement</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/11-12/Rental_Agreement_11-12.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Deed to desk</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/11-12/Desk_Deed_11-12.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Personal finance</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Budget log</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/11-12/Budget_Log_11-12.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Credit card research assignment</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/11-12/Credit_Card_Research_11-12.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Insurance log</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/11-12/Insurance_Log_11-12.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Investing</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Investment log</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/11-12/Investment_Log_11-12.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Investment simulator student journal</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/11-12/Investment_Journal_11-12.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Investment simulator teacher's guide</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/11-12/Investment_Teachers_Guide_11-12.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Auctions</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Auction record form</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/11-12/Auction_Record_11-12.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Donation request letter</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/11-12/Letter_Donation_Request_11-12.docx')"><img
                  src="assets/images/doc_link.svg"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Year-end certificates</h3>
            <table class="table">
              <tbody>
              <tr>
                <td>Certificate of achievement</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/en/11-12/Achievement_Cert_11-12.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Supplies to have on hand</h3>
            <table class="table">
              <tbody>
              <tr>
                <td>Portfolio folders</td>
              </tr>
              <tr>
                <td>Shoebox</td>
              </tr>
              <tr>
                <td>Auction items</td>
              </tr>
              </tbody>
            </table>
          </div>

          <h3 class="about nineten spanish hidden">Grados 11–12</h3>
          <div class="infoBlock spanish hidden">
            <h3>Elementos introductorios</h3>
            <table class="table">
              <tbody>
              <tr>
                <td>Guía del programa 11—12 <sub>(website's
                  program section)</sub></td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/11-12/Program_Guide_11-12_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Carta para los padres</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/11-12/Letter_Parents_11-12_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Carta para los administradores</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/11-12/Letter_Administrator_11-12_ES.docx')"><img
                  src="assets/images/doc_link.svg"></a></td>
              </tr>
              <tr>
                <td>Silaba</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/11-12/Syllabus_11-12_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Empleo</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Aplicación de trabajo</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/11-12/Job_Application_11-12_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Oferta de trabajo</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/11-12/Job_Offer_11-12_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Licencia de negocios</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/11-12/Business_License_11-12_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Lista de verificación del auditor</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/11-12/Auditor_Checklist_11-12_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Bancario/a</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Recibos bancarios</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/11-12/Bank_Log_11-12_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Aviso de factura sin pagar</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/11-12/Unpaid_Bill_11-12_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Registro de pago de facturas</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/11-12/Bill_Pay_Log_11-12_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Formulario de registro de impuestos</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/11-12/Tax_Record_11-12_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Registro de energía del aula</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/11-12/Energy_Log_11-12_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Bonificaciones y multas</h3>
            <table class="table">
              <tbody>
              <tr>
                <td>Boletos de bonificación/multa</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/11-12/Bonus-Fine_Tickets_11-12_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Registro de bonificación/multa</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/11-12/Bonus-Fine_Log_11-12_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Alquiler y título de propiedad</h3>
            <table
              class="table">

              <tbody>
              <tr>
                <td>Contrato de alquiler</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/11-12/Rental_Agreement_11-12_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Título de propiedad del escritorio</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/11-12/Desk_Deed_11-12_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Personal finance</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Budget log</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/11-12/Budget_Log_11-12_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Credit card research assignment</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/11-12/Credit_Card_Research_11-12_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Insurance log</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/11-12/Insurance_Log_11-12_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Invertir</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Registro de inversiones</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/11-12/Investment_Log_11-12_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Reporte de estudiante de simulador de
                  inversión
                </td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/11-12/Investment_Journal_11-12_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Guía del profesor del simulador de
                  inversiones
                </td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/11-12/Investment_Teachers_Guide_11-12_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Subastas</h3>
            <table class="table">

              <tbody>
              <tr>
                <td>Forma de registro de subasta</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/11-12/Auction_Record_11-12_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              <tr>
                <td>Carta de solicitud de donación</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/11-12/Letter_Donation_Request_11-12_ES.docx')"><img
                  src="assets/images/doc_link.svg"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Certificados de fin de año</h3>
            <table class="table">
              <tbody>
              <tr>
                <td>Certificado de logro</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('assets/printables/es/11-12/Achievement_Cert_11-12_ES.pdf')"><img
                  src="assets/images/pdf_icon.png"></a></td>
              </tr>
              </tbody>
            </table>

            <h3>Suministros para tener al alcance</h3>
            <table
              class="table">

              <tbody>
              <tr>
                <td>Carpetas de portafolios</td>
              </tr>
              <tr>
                <td>Shoebox</td>
              </tr>
              <tr>
                <td>Artículos de subasta</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- VIDEOS -->
        <div class="gradesInnerContent hidden" id="videoMaterials">
          <h3 class="about english">Videos</h3>
          <div class="infoBlock english">
            <p><i>To save a video to your computer, right-click on
              the "Play" icon and select "Save target
              as...", "Download linked file as...", or "Save
              link as..."</i></p>
            <h3>Download intro videos</h3>
            <table class="table">
              <tbody>
              <tr>
                <td>Intro</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/3064_MCE_MainVideo.mp4')"><img
                  src="assets/images/video_icon.svg"></a></td>
              </tr>
              <tr>
                <td>How it works</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/3064_MCE_HowItWorks.mp4')"><img
                  src="assets/images/video_icon.svg"></a></td>
              </tr>
              <tr>
                <td>Bonuses</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/3064_MCE_Bonuses.mp4')"><img
                  src="assets/images/video_icon.svg"></a></td>
              </tr>
              <tr>
                <td>Rent and fines</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/3064_MCE_RentFines.mp4')"><img
                  src="assets/images/video_icon.svg"></a></td>
              </tr>
              <tr>
                <td>Bills and taxes</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/3064_MCE_BillsTaxes.mp4')"><img
                  src="assets/images/video_icon.svg"></a></td>
              </tr>
              <tr>
                <td>Auctions</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/3064_MCE_Auctions.mp4')"><img
                  src="assets/images/video_icon.svg"></a></td>
              </tr>
              <tr>
                <td>Investing</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/3064_MCE_Investing.mp4')"><img
                  src="assets/images/video_icon.svg"></a></td>
              </tr>
              <tr>
                <td>Insurance</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/3064_MCE_Insurance.mp4')"><img
                  src="assets/images/video_icon.svg"></a></td>
              </tr>
              </tbody>
            </table>
          </div>
          <h3 class="about spanish hidden">Videos</h3>
          <div class="infoBlock spanish hidden">
            <p><i>Para guardar un video en su computadora, haga clic
              derecho en el ícono "Reproducir" y
              seleccione "Save target as...", "Download linked
              file as...", or "Save link as..."</i></p>
            <h3>Descargar videos de introducción</h3>
            <table
              class="table">

              <tbody>
              <tr>
                <td>Introducción</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/3064_MCE_MainVideo.mp4')"><img
                  src="assets/images/video_icon.svg"></a></td>
              </tr>
              <tr>
                <td>Como funciona</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/3064_MCE_HowItWorks.mp4')"><img
                  src="assets/images/video_icon.svg"></a></td>
              </tr>
              <tr>
                <td>Bonos</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/3064_MCE_Bonuses.mp4')"><img
                  src="assets/images/video_icon.svg"></a></td>
              </tr>
              <tr>
                <td>Renta y Multas</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/3064_MCE_RentFines.mp4')"><img
                  src="assets/images/video_icon.svg"></a></td>
              </tr>
              <tr>
                <td>Facturas e impuestos</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/3064_MCE_BillsTaxes.mp4')"><img
                  src="assets/images/video_icon.svg"></a></td>
              </tr>
              <tr>
                <td>Subastas</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/3064_MCE_Auctions.mp4')"><img
                  src="assets/images/video_icon.svg"></a></td>
              </tr>
              <tr>
                <td>Seguros</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/3064_MCE_Investing.mp4')"><img
                  src="assets/images/video_icon.svg"></a></td>
              </tr>
              <tr>
                <td>Inversiones</td>
                <td><a href="javascript:void(0);" (click)="modalService.openMaterialsForm('https://vanguard.vo.llnwd.net/o1/EXT/Corp/MCE/3064_MCE_Insurance.mp4')"><img
                  src="assets/images/video_icon.svg"></a></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="hidden" id="orderForms">
        <div class="col-md-4 sidebar">
          <div class="sidebarNav">
            <ul class="panel-collapse collapse in accordion"
                id="collapse1">
              <li class="active sideNav" href="#complete"
                  id="completeSideNav">Order a complete kit
              </li>
              <li class="sideNav" href="#individual"
                  id="individualSideNav">Order individual items
              </li>
            </ul>
          </div>
        </div>

        <div class="col-md-8 gradeContent">
          <div class="gradesInnerContent" id="complete">
            <h3 class="about k1">Order a complete kit</h3>
            <div class="infoBlock">
              <p>If your school lacks resources to print or copy
                materials from this site, Vanguard will mail
                you a packet that includes the classroom
                currency, bank logs, and other items. Your
                classroom materials are sent by FedEx. Please
                note that FedEx does not deliver to a P.O.
                Box, therefore, you must provide a physical
                address to receive your classroom materials.
                Please allow up to ten days for receipt.<strong>
                  If your school is currently closed, please
                  use a home address as someone needs to be
                  present to sign for the package upon arrival.
                </strong></p>


              <form action="materials" id="kitform"
                    method="post" name="orderkit">

                                <span class="inline">
                                    <select name="gradelevel" style="width:100%;
                                        margin-top:30px;">
                                        <option value="">--Grade level--</option>
                                        <option value="K">
                                            Kindergarten</option>
                                        <option value="1">
                                            1st Grade</option>
                                        <option value="2">
                                            2nd Grade</option>
                                        <option value="3">
                                            3rd Grade</option>
                                        <option value="4">
                                            4th Grade</option>
                                        <option value="5">
                                            5th Grade</option>
                                        <option value="6">
                                            6th Grade</option>
                                        <option value="7">
                                            7th Grade</option>
                                        <option value="8">
                                            8th Grade</option>
                                        <option value="9">
                                            9th Grade</option>
                                        <option value="10">
                                            10th Grade</option>
                                        <option value="11">
                                            11th Grade</option>
                                        <option value="12">
                                            12th Grade</option>
                                    </select>
                                </span>

                <span class="inline">
                                    <input aria-invalid="false" aria-required="true" name="students"
                                           placeholder="Number of
                                        students" size="40"
                                           type="text">
                                </span>

                <span class="inline">
                                    <input aria-invalid="false" aria-required="true" name="firstname"
                                           placeholder="Teacher's
                                        first name" size="40"
                                           type="text">
                                </span>

                <span class="inline">
                                    <input aria-invalid="false" aria-required="true" name="lastname"
                                           placeholder="Teacher's
                                        last name" size="40"
                                           type="text">
                                </span>

                <input aria-invalid="false"
                       aria-required="true"
                       name="email" placeholder="you@youremail.com"
                       size="40" type="email">


                <span class="inline">
                                    <input aria-invalid="false" aria-required="true"
                                           name="school"
                                           placeholder="School name" size="40"
                                           type="text">
                                </span>

                <span class="inline">
                                    <input aria-invalid="false" aria-required="true" name="address"
                                           placeholder="Street
                                        address" size="40"
                                           type="text">
                                </span>

                <span class="inline">
                                    <input aria-invalid="false" aria-required="true"
                                           name="city"
                                           placeholder="City" size="40"
                                           type="text">
                                </span>
                <span class="inline">
                                    <input aria-invalid="false" aria-required="true" name="state"
                                           placeholder="State /
                                        Province / Region" size="40"
                                           type="text">
                                </span>

                <span class="inline">
                                    <input aria-invalid="false" aria-required="true" name="zip"
                                           placeholder="ZIP / Postal
                                        Code" size="40"
                                           type="text">
                                </span>
                <span class="inline">
                                    <input aria-invalid="false" aria-required="true"
                                           name="phone"
                                           placeholder="Phone" size="40"
                                           type="tel">
                                </span>
                <span class="inline">
                                    <div style="width:15%;float:left;">
                                        <input class="available-checkbox" name="acknowledgePickup"
                                               style="width: 40px; height:
                                            40px;"
                                               type="checkbox">
                                    </div>
                                    <strong style="float: left; width: 85%;
                                        margin-top: 10px;">Available to sign for
                                        package
                                        at this address</strong>
                                </span>
                <input class="btn btn-default" id="ordersubmit"
                       name="orderkit" type="submit"
                       value="Order Kit">
              </form>
            </div>
          </div>

          <div class="gradesInnerContent hidden" id="individual">
            <h3 class="about k1">Order individual items</h3>
            <div class="infoBlock">
              <p>If your school lacks resources to print or copy
                materials from this site, Vanguard will mail
                you a packet that includes the classroom
                currency, bank logs, and other items. Your
                classroom materials are sent by FedEx. Please
                note that FedEx does not deliver to a P.O.
                Box, therefore, you must provide a physical
                address to receive your classroom materials.
                Please allow up to ten days for receipt.</p>
              <form action="materials" id="itemform"
                    method="post" name="orderitems">
                <div class="row">
                  <h3>Introductory items</h3>
                  <span class="checkbox"><input
                    name="items[program][]"
                    type="checkbox" value="guidek-1">
                                        Program Guide K–1</span>
                  <span class="checkbox"><input
                    name="items[program][]"
                    type="checkbox" value="guide2-3">Program
                                        Guide 2–3</span>
                  <span class="checkbox"><input
                    name="items[program][]"
                    type="checkbox" value="guide4-5">Program
                                        Guide 4–5</span>
                  <span class="checkbox"><input
                    name="items[program][]"
                    type="checkbox" value="guide4-5">Program
                                        Guide 6</span>
                  <span class="checkbox"><input
                    name="items[program][]"
                    type="checkbox" value="guide4-5">Program
                                        Guide 7–8</span>
                  <span class="checkbox"><input
                    name="items[program][]"
                    type="checkbox" value="guide4-5">Program
                                        Guide 9–10</span>
                  <span class="checkbox"><input
                    name="items[program][]"
                    type="checkbox" value="guide4-5">Program
                                        Guide 11–12</span>
                  <span class="checkbox"><input
                    name="items[program][]"
                    type="checkbox"
                    value="parent-letter">Letter to
                                        parents 2–8</span>
                  <span class="checkbox"><input
                    name="items[program][]"
                    type="checkbox"
                    value="parent-letter">Letter to
                                        parents 9–12</span>
                  <span class="checkbox"><input
                    name="items[program][]"
                    type="checkbox"
                    value="admin-letter">Letter to
                                        administrator k–6</span>
                  <span class="checkbox"><input
                    name="items[program][]"
                    type="checkbox"
                    value="admin-letter">Letter to
                                        administrator 7–8</span>
                  <span class="checkbox"><input
                    name="items[program][]"
                    type="checkbox"
                    value="admin-letter">Letter to
                                        administrator 9–12</span>
                  <span class="checkbox"><input
                    name="items[program][]"
                    type="checkbox"
                    value="admin-letter">Donation
                                        request letter 7–12</span>


                  <h3>Cash (# of sheets)</h3>
                  <input maxlength="5" name="items[1bills]"
                         size="5" type="text">$1 bills <sup>(six
                  per sheet, or $6 total)</sup>
                  <input maxlength="5" name="items[5bills]"
                         size="5" type="text">$5 bills <sup>(six
                  per sheet, or $30 total)</sup>
                  <input maxlength="5" name="items[10bills]"
                         size="5" type="text">$10 bills <sup>(six
                  per sheet, or $60 total)</sup>
                  <input maxlength="5" name="items[20bills]"
                         size="5" type="text">$20 bills <sup>(six
                  per sheet, or $120 total)</sup>
                  <input maxlength="5" name="items[50bills]"
                         size="5" type="text">$50 bills <sup>(six
                  per sheet, or $300 total)</sup>
                  <input maxlength="5" name="items[100bills]"
                         size="5" type="text">$100 bills <sup>(six
                  per sheet, or $600 total)</sup>
                  <input maxlength="5" name="items[500bills]"
                         size="5" type="text">$500 bills <sup>(six
                  per sheet, or $3000 total)</sup>

                  <h3>Certificates # of sheets</h3>
                  <input maxlength="3" name="items[deed]"
                         size="3" type="text">Deed to desk 2-8
                  <input maxlength="3" name="items[deed]"
                         size="3" type="text">Deed to desk 9-12
                  <input maxlength="3" name="items[saver2-3]"
                         size="3" type="text">Saver certificate
                  K-1
                  <input maxlength="3" name="items[saver2-3]"
                         size="3" type="text">Saver certificate
                  2-3
                  <input maxlength="3"
                         name="items[supersaver2-3]" size="3" type="text">Super
                  saver certificate 2-3
                  <input maxlength="3"
                         name="items[ultimatesaver2-3]" size="3" type="text">Ultimate
                  saver certificate 2-3
                  <input maxlength="3" name="items[saver4-5]"
                         size="3" type="text">Saver certificate
                  4-8
                  <input maxlength="3"
                         name="items[supersaver4-5]" size="3" type="text">Super
                  saver certificate 4-8
                  <input maxlength="3"
                         name="items[ultimatesaver4-5]" size="3" type="text">Ultimate
                  saver 4-8
                  <input maxlength="3"
                         name="items[ultimatesaver4-5]" size="3" type="text">Certificate
                  of achievement 9-12
                  <input maxlength="3"
                         name="items[ultimatesaver4-5]" size="3" type="text">Auditor
                  checklist 9-12
                </div>

                <div class="row">
                  <h3>Recordkeeping # of sheets</h3>
                  <input maxlength="3"
                         name="items[jobapplication]" size="3" type="text">Job
                  application 2-6
                  <input maxlength="3"
                         name="items[jobapplication]" size="3" type="text">Job
                  application 7-8
                  <input maxlength="3"
                         name="items[jobapplication]" size="3" type="text">Job
                  application 9-12
                  <input maxlength="3" name="items[joboffer]"
                         size="3" type="text">Job offer 4-12
                  <br>
                  <input maxlength="3" name="items[paychecks]"
                         size="3" type="text">Paychecks 2-8
                  <sup>(four per sheet)</sup> <br>
                  <input maxlength="3" name="items[banklog2-3]"
                         size="3" type="text">Bank log 2-3
                  <input maxlength="3" name="items[banklog4-5]"
                         size="3" type="text">Bank log 4-6
                  <input maxlength="3" name="items[banklog4-5]"
                         size="3" type="text">Class bank log
                  7-8
                  <input maxlength="3" name="items[banklog4-5]"
                         size="3" type="text">Class bank log
                  9-12
                  <input maxlength="3" name="items[bankslip]"
                         size="3" type="text">Bank slips 4-8
                  <sup>(four per sheet)</sup> <br>
                  <input maxlength="3"
                         name="items[finetickets2-3]" size="3" type="text">Fine
                  tickets 2-3 <sup>(four per sheet)</sup>
                  <input maxlength="3"
                         name="items[finetickets4-5]" size="3" type="text"> Fine
                  tickets 4-8 <sup>(four per sheet)</sup>
                  <input maxlength="3"
                         name="items[finetickets4-5]" size="3" type="text">Bonus/fine
                  slips 9-12 <sup>(four per sheet)</sup>
                  <input maxlength="3"
                         name="items[rentagreement]" size="3" type="text">Rental
                  agreement 4-8 <sup>(two per sheet)</sup>
                  <input maxlength="3"
                         name="items[rentagreement]" size="3" type="text">
                  Rental agreement 9-12 <sup>(two per sheet)
                </sup>
                  <input maxlength="3" name="items[rentlog]"
                         size="3" type="text">Rent log 4-8
                  <input maxlength="3" name="items[unpaidrent]"
                         size="3" type="text">Unpaid rent
                  notice 2-6 <sup>(four per sheet)</sup>
                  <input maxlength="3" name="items[unpaidrent]"
                         size="3" type="text">Unpaid rent
                  notice 7-8 <sup>(four per sheet)</sup>
                  <input maxlength="3" name="items[unpaidrent]"
                         size="3" type="text"> Unpaid bill
                  notice 9-12 <sup>(four per sheet)</sup>
                  <br>
                  <input maxlength="3"
                         name="items[auctionrecord2-3]" size="3" type="text">Auction
                  record form 2-3
                  <input maxlength="3"
                         name="items[auctionrecord4-5]" size="3" type="text">Auction
                  record form 4-8
                  <input maxlength="3"
                         name="items[auctionrecord4-5]" size="3" type="text">Auction
                  record form 9-12
                  <input maxlength="3"
                         name="items[auctionpaddle]" size="3" type="text">Auction
                  paddle card stock
                  <br>
                  <input maxlength="3" name="items[offenselog]"
                         size="3" type="text">Offense log 2-8
                  <input maxlength="3" name="items[offenselog]"
                         size="3" type="text">Bill payment log
                  7-8
                  <input maxlength="3" name="items[offenselog]"
                         size="3" type="text">Bill payment log
                  9-12
                  <input maxlength="3" name="items[offenselog]"
                         size="3" type="text">Classroom energy
                  log 7-12
                  <input maxlength="3" name="items[offenselog]"
                         size="3" type="text">Business license
                  7-12
                  <input maxlength="3" name="items[offenselog]"
                         size="3" type="text">Tax record and
                  form 7-8
                  <input maxlength="3" name="items[offenselog]"
                         size="3" type="text">Tax record and
                  form 9-12

                </div>

                <div class="rowLong">
                                    <span class="inline">
                                        <input aria-invalid="false" aria-required="true" name="students"
                                               placeholder="Number
                                            of students" size="40"
                                               type="text">
                                    </span>

                  <span class="inline">
                                        <input aria-invalid="false"
                                               aria-required="true"
                                               name="firstname"
                                               placeholder="Teacher's first name" size="40"
                                               type="text">
                                    </span>

                  <span class="inline">
                                        <input aria-invalid="false"
                                               aria-required="true"
                                               name="lastname"
                                               placeholder="Teacher's last name" size="40"
                                               type="text">
                                    </span>

                  <span class="inline">
                                        <input aria-invalid="false"
                                               aria-required="true"
                                               name="email"
                                               placeholder="your@email.com" size="40"
                                               type="email">
                                    </span>

                  <span class="inline">
                                        <input aria-invalid="false" aria-required="true" name="school"
                                               placeholder="School
                                            name" size="40"
                                               type="text">
                                    </span>

                  <span class="inline">
                                        <input aria-invalid="false" aria-required="true" name="address"
                                               placeholder="Street
                                            address" size="40"
                                               type="text">
                                    </span>

                  <span class="inline">
                                        <input aria-invalid="false" aria-required="true"
                                               name="city"
                                               placeholder="City" size="40"
                                               type="text">
                                    </span>

                  <span class="inline">
                                        <input aria-invalid="false" aria-required="true"
                                               name="state"
                                               placeholder="State /
                                            Province / Region" size="40"
                                               type="text">
                                    </span>

                  <span class="inline">
                                        <input aria-invalid="false" aria-required="true" name="zip"
                                               placeholder="ZIP /
                                            Postal Code" size="40"
                                               type="text">
                                    </span>

                  <span class="inline">
                                        <input aria-invalid="false" aria-required="true"
                                               name="phone"
                                               placeholder="Phone" size="40"
                                               type="tel">
                                    </span>
                  <span class="inline">
                                        <div style="width:15%;float:left;">
                                            <input class="available-checkbox"
                                                   name="acknowledgePickup"
                                                   style="width: 40px; height:
                                                40px;"
                                                   type="checkbox">
                                        </div>
                                        <strong style="float: left; width: 85%;
                                            margin-top: 10px;">Available to sign
                                            for
                                            package at this address</strong>
                                    </span>
                  <input class="btn
                                        btn-default" id="ordersubmit" name="orderitems" type="submit" value="Order
                                        Items">
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>