import { Component, OnInit } from "@angular/core";
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    DropdownMenuData,
    DropdownMenuItem } from '@vg-constellation/angular-15/dropdown';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { 
    maxLengths,
    regexPatterns,
    detectIOS,
    isValidZip } from "../shared/utilities/validators.utility";
import { Router } from "@angular/router";
import { ApiService } from "../shared/services/api.service";
import Cookies from "js-cookie";
import { ToastrService } from "ngx-toastr";
import { 
    GRADE_LEVEL_LIST,
    REFERRAL_LIST,
    CONFERENCE_LIST,
    PARTNERSHIP_LIST,
    SIGNUP_EXTERNAL_LINKS } from "../shared/config/app.constants";
import { Observable, map } from "rxjs";
import { AdobeLaunchService } from "../shared/services/adobe-launch.service";

@Component({
    selector: 'app-materials-form',
    templateUrl: './materials-form.component.html',
    styleUrls: ['./materials-form.component.scss']
})
export class MaterialsFormComponent implements OnInit{
    public materialUrlPath: string;
    fileDetails;
    materialsForm: FormGroup;
    isSubmittedAndInvalid = false;
    isSaving = false;
    checkOS: boolean;
    signupExternalLinks = SIGNUP_EXTERNAL_LINKS;
    dataCollectionMessage = `
        We are collecting this data to better understand who is using My Classroom Economy and to be able to share 
        with you important updates regarding this free program.
        <br>
        <br>
        Please know that the Vanguard Group, sponsor of the My Classroom Economy program, is committed to maintaining 
        the privacy of your personal information. If you're interested in learning more, the links to our 
        Terms and Conditions and Privacy Policy are below.
    `;

    gradeLevelList: DropdownMenuData;
    gradeLevelItemList: DropdownMenuItem[] = [];
    referenceList: DropdownMenuData;
    referenceItemList: DropdownMenuItem[] = [];
    conferenceList: DropdownMenuData;
    conferenceItemList: DropdownMenuItem[] = [];
    partnershipList: DropdownMenuData;
    partnershipItemList: DropdownMenuItem[] = [];

    selectedGradeLevelArray: [] = [];
    selectedGrades = '';
    selectedReferral = '';

    readonly maxEmailLength = maxLengths.email;
    readonly maxSchoolNameLength = maxLengths.schoolName;

    constructor(
        public _router: Router,
        private readonly fb: FormBuilder,
        private readonly _apiService: ApiService,
        public bsModalRef: BsModalRef,
        private readonly toast: ToastrService,
        private readonly adobeLaunchService: AdobeLaunchService
    ) {
        this.createGradeListDropdown();
        this.createReferralListDropdown();
        this.createConferenceListDropdown();
        this.createPartnershipListDropdown();
    }

    ngOnInit(): void {
        this.checkOS = detectIOS();
        this.initForm();

        //Adobe launch implementation
        this.adobeLaunchService.trackSubPage('modal_download_material_open');
    }

    initForm(): void {
        this.materialsForm = this.fb.group({
            firstName: [
                '', 
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(maxLengths.firstName),
                    Validators.pattern(regexPatterns.name)
                ])
            ],
            lastName: [
                '', 
                Validators.compose([
                    Validators.maxLength(maxLengths.lastName),
                    Validators.pattern(regexPatterns.name)
                ])
            ],
            email: [
                '', 
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(maxLengths.email),
                    Validators.pattern(regexPatterns.email)
                ])
            ],
            schoolName: [
                '', 
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(maxLengths.schoolName),
                    Validators.pattern(regexPatterns.name)
                ])
            ],
            schoolZipCode: [
                '', 
                Validators.compose([
                    Validators.required
                ])
            ],
            gradeLevel: [
                '', 
                Validators.compose([
                    Validators.required
                ]),
            ],
            reference: [
                '', 
                Validators.compose([
                    Validators.required
                ]),
            ],
            conference: [
                ''
            ],
            partnership: [
                ''
            ],
            isTermsAndConditionsAccepted: [
                false, 
                Validators.compose([
                    Validators.requiredTrue
                ])
            ],
            isPrivacyPolicyAccepted: [
                false, 
                Validators.compose([
                    Validators.requiredTrue
                ])
            ],
            captchaResponse: [
                '',
                Validators.compose([
                    Validators.required
                ])
            ]
        });
    
        if (this.checkOS) {
            this.materialsForm.controls["schoolZipCode"].setAsyncValidators(this.zipCodeValidator());
        } else {
            this.materialsForm.controls["schoolZipCode"].addValidators(isValidZip());
        }
    }

    zipCodeValidator(): AsyncValidatorFn {
        return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
          return this._apiService.validateZipCode(control.value)
            .pipe(
              map((res: any) => {
                return res.message ? null : { inValidZip: true };
              })
            );
        };
      }

    createGradeListDropdown() {
        Object.entries(GRADE_LEVEL_LIST).forEach(([key, value]) => {
          this.gradeLevelItemList.push({
            labelText: value,
            id: key
          });
        });
        this.gradeLevelList = {
          groups: [
            {
              items: this.gradeLevelItemList
            }
          ]
        };
    }

    createReferralListDropdown() {
        Object.entries(REFERRAL_LIST).forEach(([key, value]) => {
        this.referenceItemList.push({
                labelText: value,
                id: key
            });
        });
        this.referenceList = {
            groups: [
                {
                items: this.referenceItemList
                }
            ]
        };
    }

    createConferenceListDropdown() {
        Object.entries(CONFERENCE_LIST).forEach(([key, value]) => {
            this.conferenceItemList.push({
                    labelText: value,
                    id: key
                });
            });
            this.conferenceList = {
                groups: [
                    {
                    items: this.conferenceItemList
                    }
                ]
            };
        }

    createPartnershipListDropdown() {
        Object.entries(PARTNERSHIP_LIST).forEach(([key, value]) => {
            this.partnershipItemList.push({
                    labelText: value,
                    id: key
                });
            });
            this.partnershipList = {
                groups: [
                    {
                    items: this.partnershipItemList
                    }
                ]
            };
        }

    getSelectedGrades(selectedItemData) {
        this.selectedGradeLevelArray = selectedItemData.activeItems;
        const filteredArray = [];
        this.selectedGradeLevelArray.forEach((checkedItem) => {
          filteredArray.push(GRADE_LEVEL_LIST[checkedItem]);
        });
        this.selectedGrades = filteredArray.toString();
        this.materialsForm?.controls['gradeLevel']?.setValue(
          this.selectedGradeLevelArray
        );
    }

    getSelectedReferral(selectedItemData) {
        this.materialsForm?.controls['reference']?.setValue(
            selectedItemData.activeItems.toString()
        );
        this.selectedReferral = selectedItemData.activeItems.toString();
    }

    getSelectedConference(selectedItemData) {
        this.materialsForm?.controls['conference']?.setValue(
            selectedItemData.activeItems.toString()
        );
    }

    getSelectedPartnership(selectedItemData) {
        this.materialsForm?.controls['partnership']?.setValue(
            selectedItemData.activeItems.toString()
        );
    }

    navigateAway() {
        //Adobe launch implementation
        this.adobeLaunchService.trackSubPage('modal_download_material_cancel');

        if (!this.materialUrlPath) {
            this._router.navigate(["/home"]);
        } 
        this.onModalClose();
    }

    showToastrMsg(
        header: string,
        description: string,
        type: string
    ) {
        return this.toast.show(description, header, { toastClass: type });
    }

    showErrorMsg(): void {
        this.showToastrMsg(
            'Request Failed', 
            'Please try again later. If the issue persists, please contact us at support@myclassroomeconomy.org', 
            'error'
        );
    }

    onSubmit() {
        this.isSubmittedAndInvalid = this.materialsForm.invalid;
        if (!this.materialsForm.invalid) {
            this.isSaving = true;
            this._apiService.createDigitalMaterial(this.materialsForm.value).subscribe({
                next: response => {
                    if (response.error) {
                        console.log(response.status, response.error);
                        this.showErrorMsg();
                        this.isSaving = false;
                    } else {
                        this.isSaving = false;
                        Cookies.set('canDownloadMaterials', 'true'); // Session

                        //Adobe launch implementation
                        this.adobeLaunchService.trackSubPage('modal_download_material_complete');

                        this.bsModalRef.hide();
                        if (this.materialUrlPath) {
                            //Adobe launch implementation
                            this.adobeLaunchService.contentDownloaded(this.fileDetails);

                            window.open(this.materialUrlPath, '_blank');
                        }
                    }
                },
                error: () => {
                    this.showErrorMsg();
                    this.isSaving = false;
                }
            });
        }
    }

    onModalClose() {
        this.bsModalRef.hide();
    }
}
