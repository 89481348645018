import { Component } from '@angular/core'
import Cookies from 'js-cookie'
import { environment } from './../environments/environment'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public title = 'My Classroom Economy'
  public environment = environment

  constructor(private readonly translate: TranslateService) { }

  ngOnInit() {
    this.translate.setDefaultLang('en');
    const lang = Cookies.get('lang') || 'en';
    if (lang === 'en' || lang === 'English') {
      this.translate.setDefaultLang('en');
      this.translate.use('en');
    } else if (lang === 'es' || lang === 'Español') {
      this.translate.setDefaultLang('es');
      this.translate.use('es');
    }
  }
}
