<div class="submit-confirmation container"  (keydown.escape)="onModalClose()">
    <div class="row close-button">
        <div class="col-12">
            <p class="c11n-text-md">
                <c11n-icon-button
                    [size]="'medium'"
                    variant="ghost"
                    labelText="{{ 'thankYouModal.close_btn_title' | translate }}"
                    iconName="remove"
                    (clickEvent)="onModalClose()"
                >
                </c11n-icon-button>
            </p>
        </div>
    <div class="submit-body">
        <div class="row">
            <div class="col-12">
                <img src="assets/images/green_checkmark.svg" alt="Green checkmark" class="submit-icon">
            </div>
        </div>
        <div class="submit-text">
            <div class="row">
                <div class="col-12">
                    <div class="c11n-text-2xl-headline">
                        {{ 'thankYouModal.header_beginning' | translate }}
                        <br>
                        {{ 'my_classroom_economy' | translate }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="c11n-text-md">
                        {{ 'thankYouModal.working_on_request' | translate }}
                        <br>
                        {{ 'thankYouModal.questions' | translate }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
