<div class="parallax order-classroom-materials-page">
  <div class="gradesHeaderImg container">
    <section class="backgroundImages" style="top: 0px;">
      <div class="centeredDiv zoom-images">
        <h1>{{ 'orderMaterials.title' | translate }}</h1>
        <div anchor-target="body" class="heroOverlay"
            data-600-start="background:rgba(0, 0, 0, 0.5)" data-start="background:rgba(0, 0, 0, 0)"></div>
        <div anchor-target="body" class="backgroundImage adjust-top" data-600-start="transform: scale(1.3)"
            data-start="transform: scale(1)" id="materialsHeader"></div>
      </div>
    </section>
  </div>
  <div class="container main order-materials" id="skrollr-body">
    <div *ngIf="!emailSent" class="main-content-box">
      <div class="introduction">
        <h2 class="c11n-text-lg-bold">
          {{ 'orderMaterials.description_part1' | translate }}
          <br>
          {{ 'orderMaterials.description_part2' | translate }}
        </h2>
        <div class="w-100">
          <c11n-details
            class="c11n-text-sm"
            collapseLabelText="{{ 'informationRequest.header' | translate }}"
            expandLabelText="{{ 'informationRequest.header' | translate }}"
            [labelWeight]="'normal'"
          >
            <div class="c11n-text-sm c11n-details__content">
              {{ 'informationRequest.description_part1' | translate }} 
              <br/>
              <br/>
              {{ 'informationRequest.description_part2' | translate }}
            </div>
          </c11n-details>
        </div>
      </div>
      <form class="order-materials-form" [formGroup]="orderMaterialsForm" (ngSubmit)="onSubmit()" focusInvalidInput>
        <p class="c11n-text-sm"><span class="required-asterisk">*</span> {{ 'orderMaterials.required' | translate }}</p>
        <div class="form-section">
          <h3 class="c11n-text-lg-headline m-0">{{ 'orderMaterials.sections.contact' | translate }}</h3>
          <div class="field-group">
            <div class="row w-100 inherit-row-gap">
              <div class="col-sm-6">
                <label c11n-label for="firstName">{{ 'orderMaterials.fields.firstName' | translate }} <span class="required-asterisk">*</span></label>
    
                <c11n-input [hasError]="isSubmittedAndInvalid && orderMaterialsForm.controls['firstName']?.errors">
                  <input
                    c11nInput
                    formControlName="firstName" 
                    id="firstName"
                    name="firstName"
                    type="text"
                    [attr.aria-describedby]="'first-name-description'"
                  />
                </c11n-input>
                <c11n-hint-error 
                  [hasError]="isSubmittedAndInvalid && orderMaterialsForm.controls['firstName'].hasError('required')"
                  *ngIf="isSubmittedAndInvalid && orderMaterialsForm.controls['firstName'].hasError('required')"
                  [hintErrorId]="'first-name-description'" 
                  errorText="{{ 'orderMaterials.errors.firstName.required' | translate }}"
                  >
                </c11n-hint-error>
                <c11n-hint-error 
                  [hasError]="isSubmittedAndInvalid && orderMaterialsForm.controls['firstName'].hasError('maxlength')"
                  *ngIf="isSubmittedAndInvalid && orderMaterialsForm.controls['firstName'].hasError('maxlength')"
                  [hintErrorId]="'first-name-description'"
                  errorText="{{ 'orderMaterials.errors.firstName.max' | translate }}">
                </c11n-hint-error>
                <c11n-hint-error 
                  [hasError]="isSubmittedAndInvalid && 
                    orderMaterialsForm.controls['firstName'].hasError('pattern') &&
                    !(orderMaterialsForm.controls['firstName'].hasError('maxlength'))"
                  *ngIf="isSubmittedAndInvalid && 
                    orderMaterialsForm.controls['firstName'].hasError('pattern') &&
                    !(orderMaterialsForm.controls['firstName'].hasError('maxlength'))"
                  [hintErrorId]="'first-name-description'"
                  errorText="{{ 'orderMaterials.errors.firstName.specialCharacters' | translate }}">
                </c11n-hint-error>
              </div>
              <div class="col-sm-6">
                <label c11n-label for="lastName">{{ 'orderMaterials.fields.lastName' | translate }} <span class="required-asterisk">*</span></label>
    
                <c11n-input [hasError]="isSubmittedAndInvalid && orderMaterialsForm.controls['lastName']?.errors">
                  <input
                    c11nInput
                    formControlName="lastName" 
                    id="lastName"
                    name="lastName"
                    type="text"
                    [attr.aria-describedby]="'last-name-description'"
                  />
                </c11n-input>
                <c11n-hint-error 
                  [hasError]="isSubmittedAndInvalid && orderMaterialsForm.controls['lastName'].hasError('required')"
                  *ngIf="isSubmittedAndInvalid && orderMaterialsForm.controls['lastName'].hasError('required')"
                  [hintErrorId]="'last-name-description'" 
                  errorText="{{ 'orderMaterials.errors.lastName.required' | translate }}"
                  >
                </c11n-hint-error>
                <c11n-hint-error 
                  [hasError]="isSubmittedAndInvalid && orderMaterialsForm.controls['lastName'].hasError('maxlength')"
                  *ngIf="isSubmittedAndInvalid && orderMaterialsForm.controls['lastName'].hasError('maxlength')"
                  [hintErrorId]="'last-name-description'"
                  errorText="{{ 'orderMaterials.errors.lastName.max' | translate }}">
                </c11n-hint-error>
                <c11n-hint-error 
                  [hasError]="isSubmittedAndInvalid && orderMaterialsForm.controls['lastName'].getError('pattern') && !(orderMaterialsForm.controls['lastName'].hasError('maxlength'))"
                  *ngIf="isSubmittedAndInvalid && orderMaterialsForm.controls['lastName'].getError('pattern') && !(orderMaterialsForm.controls['lastName'].hasError('maxlength'))"
                  [hintErrorId]="'last-name-description'"
                  errorText="{{ 'orderMaterials.errors.lastName.specialCharacters' | translate }}">
                </c11n-hint-error>
              </div>
            </div>
            <div class="row w-100 inherit-row-gap">
              <div class="col-sm-12">
                <label c11n-label for="email">{{ 'orderMaterials.fields.email' | translate }} <span class="required-asterisk">*</span></label>
                <c11n-input
                  [hasError]="isSubmittedAndInvalid && orderMaterialsForm.controls['email']?.errors">
                    <input
                      c11nInput
                      placeholder="user@domain.com"
                      formControlName="email" 
                      id="email"
                      name="email"
                      type="email"
                      [attr.aria-describedby]="'email-description'"
                    />
                </c11n-input>
                <c11n-hint-error 
                  [hasError]="isSubmittedAndInvalid && 
                    (orderMaterialsForm.controls['email'].getError('required') || orderMaterialsForm.controls['email'].getError('pattern'))"
                  *ngIf="isSubmittedAndInvalid && 
                    (orderMaterialsForm.controls['email'].getError('required') || orderMaterialsForm.controls['email'].getError('pattern'))"
                  [hintErrorId]="'email-description'"
                  errorText="{{ 'orderMaterials.errors.email.invalid' | translate }}" 
                  >
                </c11n-hint-error>
                <c11n-hint-error 
                  [hasError]="isSubmittedAndInvalid && 
                    orderMaterialsForm.controls['email'].hasError('maxlength') &&
                    !(orderMaterialsForm.controls['email'].getError('required') || orderMaterialsForm.controls['email'].getError('pattern'))"
                  *ngIf="isSubmittedAndInvalid && 
                    orderMaterialsForm.controls['email'].hasError('maxlength') &&
                    !(orderMaterialsForm.controls['email'].getError('required') || orderMaterialsForm.controls['email'].getError('pattern'))"
                  [hintErrorId]="'email-description'"
                  errorText="{{ 'orderMaterials.errors.email.max' | translate }}" 
                  >
                </c11n-hint-error>
              </div>
            </div>
          </div>
        </div>

        <div class="form-section">
          <h3 class="c11n-text-lg-headline m-0">{{ 'orderMaterials.sections.school' | translate }}</h3>
          <div class="field-group">
            <div class="row w-100 inherit-row-gap">
              <div class="col-sm-12">
                <label c11n-label for="schoolName">{{ 'orderMaterials.fields.school' | translate }} <span class="required-asterisk">*</span></label>
                <c11n-input 
                  [hasError]="isSubmittedAndInvalid && orderMaterialsForm.controls['schoolName']?.errors">
                    <input
                      c11nInput
                      formControlName="schoolName" 
                      id="schoolName"
                      name="schoolName"
                      [attr.aria-describedby]="'school-name-description'"
                    />
                </c11n-input>
                <c11n-hint-error 
                [hasError]="isSubmittedAndInvalid && orderMaterialsForm.controls['schoolName'].getError(
                  'required'
                )"
                *ngIf="isSubmittedAndInvalid && orderMaterialsForm.controls['schoolName'].getError(
                  'required'
                )"
                [hintErrorId]="'school-name-description'" 
                errorText="{{ 'orderMaterials.errors.school.required' | translate }}" 
                >
                </c11n-hint-error>
                <c11n-hint-error 
                  [hasError]="isSubmittedAndInvalid && orderMaterialsForm.controls['schoolName'].getError('maxlength')"
                  *ngIf="isSubmittedAndInvalid && orderMaterialsForm.controls['schoolName'].getError('maxlength')"
                  [hintErrorId]="'school-name-description'"
                  errorText="{{ 'orderMaterials.errors.school.max' | translate }}"  
                  >
                </c11n-hint-error>
                <c11n-hint-error 
                  [hasError]="isSubmittedAndInvalid && 
                    orderMaterialsForm.controls['schoolName'].getError('pattern') &&
                    !(orderMaterialsForm.controls['schoolName'].getError('maxlength'))" 
                  *ngIf="isSubmittedAndInvalid && 
                    orderMaterialsForm.controls['schoolName'].getError('pattern') &&
                    !(orderMaterialsForm.controls['schoolName'].getError('maxlength'))" 
                  [hintErrorId]="'school-name-description'" 
                  errorText="{{ 'orderMaterials.errors.school.specialCharacters' | translate }}" 
                  >
                </c11n-hint-error>
              </div>
            </div>

            <div [formArrayName]="'gradeKitOrders'" class="grade-kit-rows">
              <div *ngFor="let gradeKitOrder of gradeKitOrders.controls; index as i;" class="row w-100 grade-kit-group" [formGroupName]="i">
                <div class="col-7 col-md-4">
                  <label c11n-label for="gradeLevel_{{ i }}" >{{ 'orderMaterials.fields.gradeLevel' | translate }} <span class="required-asterisk">*</span></label>
                  <c11n-select
                    [hideLabel]="true"
                    [hintText]="''"
                    [hasError]="isSubmittedAndInvalid && gradeKitOrders.controls[i].controls?.gradeLevel?.errors"
                    errorText="{{ 'orderMaterials.errors.grade.required' | translate }}" >
                    <select 
                      c11nSelectInput 
                      id="gradeLevel_{{ i }}"
                      formControlName="gradeLevel"
                      required 
                      attr.aria-describedby="grade-level-{{ i }}-description">
                      <option value="" data-option="placeholder" disabled selected>
                        {{ 'orderMaterials.select' | translate }}
                      </option>
                      <option *ngFor="let grade of grades | keyvalue : preserveOriginalOrder" [ngValue]="grade.key">{{ grade.value }}</option>
                    </select>
                  </c11n-select>
                </div>
                <div class="col-5 col-md-3">
                  <label c11n-label for="numberOfStudents_{{ i }}">{{ 'orderMaterials.fields.numberOfStudents' | translate }} <span class="required-asterisk">*</span></label>
                  <c11n-input
                    [hasError]="isSubmittedAndInvalid && gradeKitOrders.controls[i].controls?.numberOfStudents?.errors">
                    <input 
                      c11nInput 
                      type="text"
                      inputmode="decimal"
                      formControlName="numberOfStudents"
                      id="numberOfStudents_{{ i }}"
                      autocomplete="off"
                      [imask]="integerMask"
                      [unmask]="true"
                      attr.aria-describedby="number-of-students-{{ i }}-description"
                      />
                  </c11n-input>
                  <c11n-hint-error
                    [hasError]="isSubmittedAndInvalid && gradeKitOrders.controls[i].controls?.numberOfStudents?.getError('required')"
                    *ngIf="isSubmittedAndInvalid && gradeKitOrders.controls[i].controls?.numberOfStudents?.getError('required')"
                    errorText="{{ 'orderMaterials.errors.numberOfStudents.required' | translate }}" 
                    [hintErrorId]="'number-of-students-{{ i }}-description'">
                  </c11n-hint-error>
                  <c11n-hint-error
                    [hasError]="isSubmittedAndInvalid && gradeKitOrders.controls[i].controls?.numberOfStudents?.getError('min')"
                    *ngIf="isSubmittedAndInvalid && gradeKitOrders.controls[i].controls?.numberOfStudents?.getError('min')"
                    errorText="{{ 'orderMaterials.errors.numberOfStudents.min' | translate }}" 
                    [hintErrorId]="'number-of-students-{{ i }}-description'">
                  </c11n-hint-error>
                  <c11n-hint-error
                    [hasError]="isSubmittedAndInvalid && gradeKitOrders.controls[i].controls?.numberOfStudents?.getError('max')"
                    *ngIf="isSubmittedAndInvalid && gradeKitOrders.controls[i].controls?.numberOfStudents?.getError('max')"
                    errorText="{{ 'orderMaterials.errors.numberOfStudents.max' | translate }}" 
                    [hintErrorId]="'number-of-students-{{ i }}-description'">
                  </c11n-hint-error>
                </div>
                <div class="col-8 col-sm-9 col-md-4">
                  <label c11n-label [for]="'language_' + i">{{ 'orderMaterials.fields.language' | translate }} <span class="required-asterisk">*</span></label>
                  <div class="c11n-radio-group radio-buttons">
                    <fieldset id="radio-group">
                      <legend class="c11n-radio-group__legend--hidden"></legend>
                      <div class="storybook-horizontal-radios">
                        <div class="c11n-radio c11n-radio--bold-label">
                          <input class="c11n-radio__input" type="radio" formControlName="language" id="english_{{i}}" value="English">
                          <label class="c11n-radio__label" for="english_{{i}}">
                            <div class="c11n-radio__focus-element"></div>
                            <span class="c11n-radio__label-content">{{ 'language.english' | translate }}</span>
                            <span class="c11n-radio__descriptive-label">
                              {{ 'language.english' | translate }}
                            </span>
                            <svg viewBox="0 0 16 16">
                              <circle class="background" cx="8" cy="8" r="7.5"></circle>
                              <circle class="foreground" cx="8" cy="8" r="4"></circle>
                            </svg>
                          </label>
                        </div>
                    
                        <div class="c11n-radio c11n-radio--bold-label">
                          <input class="c11n-radio__input" type="radio" formControlName="language" id="spanish_{{i}}" value="Spanish">
                          <label class="c11n-radio__label" for="spanish_{{i}}">
                            <div class="c11n-radio__focus-element"></div>
                            <span class="c11n-radio__label-content c11n-text-md">{{ 'language.spanish' | translate }}</span>
                            <span class="c11n-radio__descriptive-label">
                              {{ 'language.spanish' | translate }}
                            </span>
                            <svg viewBox="0 0 16 16">
                              <circle class="background" cx="8" cy="8" r="7.5"></circle>
                              <circle class="foreground" cx="8" cy="8" r="4"></circle>
                            </svg>
                          </label>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <c11n-hint-error
                    [hasError]="isSubmittedAndInvalid && gradeKitOrders.controls[i].controls?.language?.errors"
                    [errorText]="'Please choose one.'"
                    [hintErrorId]="'language-{{ i }}-description'" >
                  </c11n-hint-error>
                </div>
                <div class="col-4 col-sm-3 col-md-1 remove-button" *ngIf="gradeKitOrders.controls.length > 1">
                  <p class="c11n-text-sm button-box">
                    <a
                      *ngIf="isSmallScreen"
                      c11n-link
                      href="javascript:void(0);"
                      [iconName]="'remove'"
                      [variant]="'secondary-independent'"
                      aria-label="Remove"
                      (click)="onRemoveRow(i)"
                    >
                      {{ 'orderMaterials.remove' | translate}}
                    </a>
                    <a
                      *ngIf="!isSmallScreen;"
                      c11n-link
                      href="javascript:void(0);"
                      [iconName]="'remove'"
                      [variant]="'secondary-independent'"
                      aria-label="Remove"
                      (click)="onRemoveRow(i)"
                    ></a> 
                  </p>
                </div>
              </div>
            </div>

            <div class="row w-100 inherit-row-gap">
              <p class="c11n-text-md">
                <button 
                  *ngIf="gradeKitOrders.length < 10"
                  type="button" 
                  c11n-link 
                  (click)="onAddRow()" 
                  [iconName]="'plus'" 
                  [iconPosition]="'leading'">
                  {{ 'orderMaterials.addGradeKit' | translate }}
                </button>
              </p>
            </div>
          </div>
        </div>

        <div class="form-section">
          <h3 class="c11n-text-lg-headline m-0">{{ 'orderMaterials.sections.shipping' | translate }}</h3>
          <div>
            <span class="c11n-text-sm--bold-italic">{{ 'orderMaterials.shippingNote.pleaseNote' | translate }}</span>
            <span class="c11n-text-sm--regular-italic"> {{ 'orderMaterials.shippingNote.message' | translate }}</span>
          </div>

          <div class="field-group">
            <div class="row w-100">
              <div class="col-sm-6">
                <label c11n-label for="country">{{ 'orderMaterials.fields.country' | translate }} <span class="required-asterisk">*</span></label>
                <c11n-select
                  [hideLabel]="true"
                  [hintText]="''"
                  [hasError]="isSubmittedAndInvalid && orderMaterialsForm.controls['country'].errors"
                  errorText="{{ 'orderMaterials.errors.country.required' | translate }}" 
                  (stateChange)="onCountryChange($event)">
                  <select c11nSelectInput required id="country" name="country" formControlName="country" [attr.aria-describedby]="'country-description'">
                    <option value="" data-option="placeholder" disabled selected>
                      {{ 'orderMaterials.select' | translate }}
                    </option>
                    <option *ngFor="let country of countries | keyvalue : preserveOriginalOrder" [value]="country.value">{{ 'countries.' + country.key | translate }}</option>
                  </select>
                </c11n-select>
              </div>
            </div>

            <div class="dynamic-address-fields" formGroupName="shippingAddress">
              <div *ngIf="orderMaterialsForm.value.country === unitedStates || orderMaterialsForm.value.country === canada" class="row w-100 create-row-gap">
                <div class="col-12">
                  <label c11n-label for="streetAddress">{{ 'orderMaterials.fields.streetAddress' | translate }} <span class="required-asterisk">*</span></label>
                  <c11n-input 
                    [hasError]="isSubmittedAndInvalid && shippingAddress.controls.streetAddress?.errors">
                      <input
                        c11nInput
                        formControlName="streetAddress" 
                        id="streetAddress"
                        name="streetAddress"
                        [attr.aria-describedby]="'street-address-description'"
                      />
                  </c11n-input>
                  <c11n-hint-error
                    hintText="{{ 'orderMaterials.streetAddressNote' | translate }}"
                    [hintErrorId]="'street-address-description'" 
                    *ngIf="!(isSubmittedAndInvalid) || !(shippingAddress.controls.streetAddress?.errors)">
                  </c11n-hint-error>
                  <c11n-hint-error 
                    [hasError]="isSubmittedAndInvalid && shippingAddress.controls.streetAddress.hasError('required')"
                    *ngIf="isSubmittedAndInvalid && shippingAddress.controls.streetAddress.hasError('required')"
                    [hintErrorId]="'street-address-description'" 
                    errorText="{{ 'orderMaterials.errors.streetAddress.required' | translate }}" 
                    >
                  </c11n-hint-error>
                  <c11n-hint-error 
                    [hasError]="isSubmittedAndInvalid && shippingAddress.controls.streetAddress.hasError('maxlength')"
                    *ngIf="isSubmittedAndInvalid && shippingAddress.controls.streetAddress.hasError('maxlength')"
                    [hintErrorId]="'street-address-description'"
                    errorText="{{ 'orderMaterials.errors.streetAddress.max' | translate }}" >
                  </c11n-hint-error>
                  <c11n-hint-error 
                    [hasError]="isSubmittedAndInvalid && 
                      shippingAddress.controls.streetAddress.hasError('pattern') &&
                      !(shippingAddress.controls.streetAddress.hasError('maxlength'))"
                    *ngIf="isSubmittedAndInvalid && 
                      shippingAddress.controls.streetAddress.hasError('pattern') &&
                      !(shippingAddress.controls.streetAddress.hasError('maxlength'))"
                    [hintErrorId]="'street-address-description'"
                    errorText="{{ 'orderMaterials.errors.streetAddress.specialCharacters' | translate }}" >
                  </c11n-hint-error>
                </div>
              </div>
  
              <div *ngIf="orderMaterialsForm.value.country === unitedStates" class="row w-100 create-row-gap">
                <div class="col-sm-6">
                  <label c11n-label for="city">{{ 'orderMaterials.fields.city' | translate }} <span class="required-asterisk">*</span></label>
                  <c11n-input 
                    [hasError]="isSubmittedAndInvalid && shippingAddress.controls.city?.errors">
                      <input
                        c11nInput
                        formControlName="city" 
                        id="city"
                        name="city"
                        [attr.aria-describedby]="'city-description'"
                      />
                  </c11n-input>
                  <c11n-hint-error 
                    [hasError]="isSubmittedAndInvalid && shippingAddress.controls.city.hasError('required')"
                    *ngIf="isSubmittedAndInvalid && shippingAddress.controls.city.hasError('required')"
                    [hintErrorId]="'city-description'" 
                    errorText="{{ 'orderMaterials.errors.city.required' | translate }}" 
                    >
                  </c11n-hint-error>
                  <c11n-hint-error 
                    [hasError]="isSubmittedAndInvalid && shippingAddress.controls.city.hasError('maxlength')"
                    *ngIf="isSubmittedAndInvalid && shippingAddress.controls.city.hasError('maxlength')"
                    [hintErrorId]="'city-description'"
                    errorText="{{ 'orderMaterials.errors.city.max' | translate }}" >
                  </c11n-hint-error>
                  <c11n-hint-error 
                    [hasError]="isSubmittedAndInvalid && 
                      shippingAddress.controls.city.hasError('pattern') &&
                      !(shippingAddress.controls.city.hasError('maxlength'))"
                    *ngIf="isSubmittedAndInvalid && 
                      shippingAddress.controls.city.hasError('pattern') &&
                      !(shippingAddress.controls.city.hasError('maxlength'))"
                    [hintErrorId]="'city-description'"
                    errorText="{{ 'orderMaterials.errors.city.specialCharacters' | translate }}" >
                  </c11n-hint-error>
                </div>
  
                <div class="col-sm-2">
                  <label c11n-label for="state">{{ 'orderMaterials.fields.state' | translate }} <span class="required-asterisk">*</span></label>
                  <c11n-input 
                    [hintText]="''"
                    [hasError]="isSubmittedAndInvalid && shippingAddress.controls.state?.errors">
                    <input 
                      c11nInput
                      aria-describedby="state-description"
                      formControlName="state"
                      id="state"
                      name="state"
                      maxlength="2"
                      oninput="this.value = this.value.toUpperCase()"
                      autocapitalize="characters"
                      type="text"/>
                  </c11n-input>
                  <c11n-hint-error 
                    [hasError]="isSubmittedAndInvalid && shippingAddress.controls.state.hasError('required')"
                    *ngIf="isSubmittedAndInvalid && shippingAddress.controls.state.hasError('required')"
                    [hintErrorId]="'state-description'"
                    errorText="{{ 'orderMaterials.errors.state.required' | translate }}" >
                  </c11n-hint-error>
                  <c11n-hint-error 
                    [hasError]="isSubmittedAndInvalid && shippingAddress.controls.state.hasError('isIn') && !(shippingAddress.controls.state.hasError('required'))"
                    *ngIf="isSubmittedAndInvalid && shippingAddress.controls.state.hasError('isIn') && !(shippingAddress.controls.state.hasError('required'))"
                    [hintErrorId]="'state-description'"
                    errorText="{{ 'orderMaterials.errors.state.invalid' | translate }}">
                  </c11n-hint-error>
                </div>
  
                <div class="col-sm-4">
                  <label c11n-label for="postalCode">{{ 'orderMaterials.fields.zipCode' | translate }} <span class="required-asterisk">*</span></label>
                  <c11n-input 
                    [hasError]="isSubmittedAndInvalid && shippingAddress.controls.postalCode?.errors">
                      <input
                        c11nInput
                        formControlName="postalCode" 
                        id="postalCode"
                        name="postalCode"
                        [attr.aria-describedby]="'postal-code-description'"
                        [imask]="zipCodeMask"
                      />
                  </c11n-input>
                  <c11n-hint-error 
                    [hasError]="isSubmittedAndInvalid && shippingAddress.controls.postalCode.hasError('required')"
                    *ngIf="isSubmittedAndInvalid && shippingAddress.controls.postalCode.hasError('required')"
                    [hintErrorId]="'postal-code-description'"
                    errorText="{{ 'orderMaterials.errors.zipCode.required' | translate }}">
                  </c11n-hint-error>
                  <c11n-hint-error 
                    [hasError]="isSubmittedAndInvalid && shippingAddress.controls.postalCode.hasError('inValidZip') && !(shippingAddress.controls.postalCode.hasError('required'))"
                    *ngIf="isSubmittedAndInvalid && shippingAddress.controls.postalCode.hasError('inValidZip') && !(shippingAddress.controls.postalCode.hasError('required'))"
                    [hintErrorId]="'postal-code-description'"
                    errorText="{{ 'orderMaterials.errors.zipCode.invalid' | translate }}">
                  </c11n-hint-error>
                </div>
              </div>
  
              <div *ngIf="orderMaterialsForm.value.country === canada" class="row w-100 create-row-gap">
                <div class="col-sm-5">
                  <label c11n-label for="city">{{ 'orderMaterials.fields.city' | translate }} <span class="required-asterisk">*</span></label>
                  <c11n-input 
                    [hasError]="isSubmittedAndInvalid && shippingAddress.controls.city?.errors">
                      <input
                        c11nInput
                        formControlName="city" 
                        id="city"
                        name="city"
                        [attr.aria-describedby]="'city-description'"
                      />
                  </c11n-input>
                  <c11n-hint-error 
                    [hasError]="isSubmittedAndInvalid && shippingAddress.controls.city.hasError('required')"
                    *ngIf="isSubmittedAndInvalid && shippingAddress.controls.city.hasError('required')"
                    [hintErrorId]="'city-description'" 
                    errorText="{{ 'orderMaterials.errors.city.required' | translate }}"
                    >
                  </c11n-hint-error>
                  <c11n-hint-error 
                    [hasError]="isSubmittedAndInvalid && shippingAddress.controls.city.hasError('maxlength')"
                    *ngIf="isSubmittedAndInvalid && shippingAddress.controls.city.hasError('maxlength')"
                    [hintErrorId]="'city-description'"
                    errorText="{{ 'orderMaterials.errors.city.max' | translate }}">
                  </c11n-hint-error>
                  <c11n-hint-error 
                    [hasError]="isSubmittedAndInvalid && 
                      shippingAddress.controls.city.hasError('pattern') &&
                      !(shippingAddress.controls.city.hasError('maxlength'))"
                    *ngIf="isSubmittedAndInvalid && 
                      shippingAddress.controls.city.hasError('pattern') &&
                      !(shippingAddress.controls.city.hasError('maxlength'))"
                    [hintErrorId]="'city-description'"
                    errorText="{{ 'orderMaterials.errors.city.specialCharacters' | translate }}">
                  </c11n-hint-error>
                </div>
  
                <div class="col-sm-4">
                  <label c11n-label for="province">Province / Territory <span class="required-asterisk">*</span></label>
                  <c11n-select
                      [hintText]="''"
                      errorText="{{ 'orderMaterials.errors.province.required' | translate }}"
                      [hasError]="isSubmittedAndInvalid && shippingAddress.controls.province?.errors"
                      [hideLabel]="true"
                      >
                      <select c11nSelectInput id="province" required formControlName="province">
                        <option value="" data-option="placeholder" disabled selected>{{ 'orderMaterials.select' | translate }}</option>
                        <option *ngFor="let province of provinces" [value]="province.canadianProvinceCode">{{ province.name }}</option>
                      </select>
                  </c11n-select>
                </div>
  
                <div class="col-sm-3">
                  <label c11n-label for="postalCode">Postal Code <span class="required-asterisk">*</span></label>
                  <c11n-input 
                    [hasError]="isSubmittedAndInvalid && shippingAddress.controls.postalCode?.errors">
                      <input
                        c11nInput
                        formControlName="postalCode" 
                        id="postalCode"
                        name="postalCode"
                        [attr.aria-describedby]="'postal-code-description'"
                      />
                  </c11n-input>
                  <c11n-hint-error 
                    [hasError]="isSubmittedAndInvalid && shippingAddress.controls.postalCode.hasError('required')"
                    *ngIf="isSubmittedAndInvalid && shippingAddress.controls.postalCode.hasError('required')"
                    [hintErrorId]="'postal-code-description'"
                    errorText="{{ 'orderMaterials.errors.postalCode.required' | translate }}">
                  </c11n-hint-error>
                  <c11n-hint-error 
                    [hasError]="isSubmittedAndInvalid && shippingAddress.controls.postalCode.hasError('pattern') && !(shippingAddress.controls.postalCode.hasError('required'))"
                    *ngIf="isSubmittedAndInvalid && shippingAddress.controls.postalCode.hasError('pattern') && !(shippingAddress.controls.postalCode.hasError('required'))"
                    [hintErrorId]="'postal-code-description'"
                    errorText="{{ 'orderMaterials.errors.postalCode.invalid' | translate }}">
                  </c11n-hint-error>
                </div>
              </div>
  
              <div *ngIf="orderMaterialsForm.value.country === other" class="row w-100 create-row-gap">
                <div>
                  <label c11n-label for="streetAddress">{{ 'orderMaterials.fields.shippingAddress' | translate }} <span class="required-asterisk">*</span></label>
                  <c11n-textarea 
                    [hasError]="isSubmittedAndInvalid && shippingAddress.controls.streetAddress?.errors">
                      <textarea
                        c11nTextarea
                        formControlName="streetAddress" 
                        id="streetAddress"
                        name="streetAddress"
                        [attr.aria-describedby]="'shipping-address-description'"
                        [rows]="4"
                      ></textarea>
                  </c11n-textarea>
                  <c11n-hint-error 
                    [hasError]="isSubmittedAndInvalid && shippingAddress.controls.streetAddress.hasError('required')"
                    *ngIf="isSubmittedAndInvalid && shippingAddress.controls.streetAddress.hasError('required')"
                    [hintErrorId]="'shipping-address-description'" 
                    errorText="{{ 'orderMaterials.errors.shippingAddress.required' | translate }}"
                    >
                  </c11n-hint-error>
                  <c11n-hint-error 
                    [hasError]="isSubmittedAndInvalid && shippingAddress.controls.streetAddress.hasError('maxlength')"
                    *ngIf="isSubmittedAndInvalid && shippingAddress.controls.streetAddress.hasError('maxlength')"
                    [hintErrorId]="'shipping-address-description'"
                    errorText="{{ 'orderMaterials.errors.shippingAddress.max' | translate }}">
                  </c11n-hint-error>
                  <c11n-hint-error 
                    [hasError]="isSubmittedAndInvalid && 
                      shippingAddress.controls.streetAddress.hasError('pattern') &&
                      !(shippingAddress.controls.streetAddress.hasError('maxlength'))"
                    *ngIf="isSubmittedAndInvalid && 
                      shippingAddress.controls.streetAddress.hasError('pattern') &&
                      !(shippingAddress.controls.streetAddress.hasError('maxlength'))"
                    [hintErrorId]="'shipping-address-description'"
                    errorText="{{ 'orderMaterials.errors.shippingAddress.specialCharacters' | translate }}">
                  </c11n-hint-error>
                </div>
              </div>
            </div>

            <div class="row w-100">
              <div class="col-sm-4">
                <label c11n-label for="phoneNumber">{{ 'orderMaterials.fields.phoneNumber' | translate }} <span class="required-asterisk">*</span></label>
                <c11n-input
                  [hasError]="isSubmittedAndInvalid && 
                  orderMaterialsForm.controls['phoneNumber'].hasError('required')"
                  >
                  <input
                    *ngIf="orderMaterialsForm.value.country === other"
                    c11nInput
                    formControlName="phoneNumber"
                    placeholder="+"
                    id="phoneNumber"
                    name="phoneNumber"
                    type="tel"
                    [attr.aria-describedby]="'phone-number-description'"/>
                  <input
                    *ngIf="orderMaterialsForm.value.country !== other"
                    c11nInput
                    formControlName="phoneNumber"
                    id="phoneNumber"
                    name="phoneNumber"
                    type="tel"
                    [imask]="domesticPhoneNumberMask"
                    [attr.aria-describedby]="'phone-number-description'"/>
                </c11n-input>
                <c11n-hint-error
                  hintText="{{ 'orderMaterials.phoneNumberNote' | translate }}"
                  *ngIf="!(isSubmittedAndInvalid) || !(orderMaterialsForm.controls['phoneNumber']?.errors)"
                  [hintErrorId]="'phone-number-description'">
                </c11n-hint-error>
                <c11n-hint-error
                  [hasError]="isSubmittedAndInvalid && orderMaterialsForm.controls['phoneNumber'].getError(
                    'required'
                  )"
                  *ngIf="isSubmittedAndInvalid && !(orderMaterialsForm.controls['phoneNumber'].getError('pattern'))"
                  errorText="{{ 'orderMaterials.errors.phoneNumber.required' | translate }}"
                  [hintErrorId]="'phone-number-description'"
                  >
                </c11n-hint-error>
                <c11n-hint-error
                  [hasError]="isSubmittedAndInvalid && orderMaterialsForm.controls['phoneNumber'].getError(
                    'pattern'
                  )"
                  *ngIf="isSubmittedAndInvalid && orderMaterialsForm.controls['phoneNumber'].getError(
                    'pattern'
                  )"
                  errorText="{{ 'orderMaterials.errors.phoneNumber.invalid' | translate }}"
                  [hintErrorId]="'phone-number-description'"
                >
                </c11n-hint-error>
              </div>
            </div>
          </div>
        </div>

        <div class="form-section">
          <h3 class="c11n-text-lg-headline m-0">{{ 'orderMaterials.sections.additional' | translate }}</h3>

          <div class="field-group">
            <div class="row w-100 inherit-row-gap">
              <div class="col-sm-7">
                <label c11n-label for="reference">{{ 'orderMaterials.fields.reference' | translate }} <span class="required-asterisk">*</span></label>
                <c11n-select
                  [hideLabel]="true"
                  [hintText]="''"
                  [hasError]="isSubmittedAndInvalid && orderMaterialsForm.controls['reference'].errors"
                  errorText="{{ 'orderMaterials.errors.reference.required' | translate }}"
                  (stateChange)="onReferenceChange($event)">
                  <select c11nSelectInput required id="reference" name="reference" formControlName="reference" [attr.aria-describedby]="'reference-description'">
                    <option value="" data-option="placeholder" disabled selected>
                      {{ 'orderMaterials.select' | translate }}
                    </option>
                    <option *ngFor="let reference of references | keyvalue" [value]="reference.key">{{ 'references.' + reference.key | translate }}</option>
                  </select>
                </c11n-select>
              </div>
            </div>
  
            <div *ngIf="orderMaterialsForm.value.reference === 'partnership'" class="row w-100 inherit-row-gap">
              <div class="col-sm-7">
                <label c11n-label for="partnership">Which partnership?</label>
                <c11n-select
                  [hideLabel]="true"
                  [hasError]="isSubmittedAndInvalid && orderMaterialsForm.controls['partnership']?.errors"
                  errorText="{{ 'orderMaterials.errors.partnership.required' | translate }}"
                  hintText="">
                  <select c11nSelectInput id="partnership" name="partnership" formControlName="partnership" required>
                    <option value="" data-option="placeholder" disabled selected>
                      {{ 'orderMaterials.select' | translate }}
                    </option>
                    <option *ngFor="let partnership of partnerships | keyvalue" [value]="partnership.key">{{ partnership.value }}</option>
                  </select>
                </c11n-select>
              </div>
            </div>
  
            <div *ngIf="orderMaterialsForm.value.reference === 'conference'" class="row w-100 inherit-row-gap">
              <div class="col-sm-7">
                <label c11n-label for="conference">Which conference?</label>
                <c11n-select
                  [hideLabel]="true"
                  [hasError]="isSubmittedAndInvalid && orderMaterialsForm.controls['conference']?.errors"
                  errorText="{{ 'orderMaterials.errors.conference.required' | translate }}"
                  hintText="">
                  <select c11nSelectInput id="conference" name="conference" formControlName="conference" required>
                    <option value="" data-option="conference" disabled selected>
                      {{ 'orderMaterials.select' | translate }}
                    </option>
                    <option *ngFor="let conference of conferences | keyvalue" [value]="conference.key">{{ conference.value }}</option>
                  </select>
                </c11n-select>
              </div>
            </div>
  
            <div class="row w-100">
              <div class="col-12 d-flex align-items-center">
                <c11n-checkbox [hideLabel]="true">
                  <input 
                    c11nCheckboxInput 
                    formControlName="isTermsAndConditionsAccepted"
                    type="checkbox"
                    aria-label="I agree to the Terms & Conditions"
                    [attr.aria-labelledby]="'isTermsAndConditionsAccepted'"
                    [attr.aria-describedby]="'terms-and-conditions-description'"/>
                </c11n-checkbox>
                  <label 
                    c11n-label
                    class="checkbox-label"
                    id="isTermsAndConditionsAccepted">
                    {{ 'orderMaterials.fields.terms.agree' | translate }}  
                    <a c11n-link 
                      [href]="signupExternalLinks.terms_conditions_url"
                      target="_blank"
                    >{{ 'orderMaterials.fields.terms.termsLink' | translate }}</a>&nbsp;<span class="required-asterisk">*</span>
                  </label>
              </div>
              <div class="col-12" *ngIf="isSubmittedAndInvalid && orderMaterialsForm.controls['isTermsAndConditionsAccepted']?.errors">
                <c11n-hint-error
                  [hasError]="isSubmittedAndInvalid && orderMaterialsForm.controls['isTermsAndConditionsAccepted'].getError('required')"
                  errorText="{{ 'orderMaterials.errors.terms.required' | translate }}" 
                  [hintErrorId]="'terms-and-conditions-description'" >
                </c11n-hint-error>
              </div>
            </div>
  
            <div class="row w-100">
              <div class="col-12 d-flex align-items-center">
                <c11n-checkbox [hideLabel]="true">
                  <input 
                    c11nCheckboxInput 
                    formControlName="isPrivacyPolicyAccepted"
                    type="checkbox"
                    aria-label="I agree to the Privacy Policy"
                    [attr.aria-labelledby]="'isPrivacyPolicyAccepted'"
                    [attr.aria-describedby]="'privacy-policy-description'"/>
                </c11n-checkbox>
                  <label 
                    c11n-label
                    class="checkbox-label"
                    id="isPrivacyPolicyAccepted">
                    {{ 'orderMaterials.fields.privacyPolicy.agree' | translate }} 
                    <a c11n-link 
                      [href]="signupExternalLinks.privacy_policy_url"
                      target="_blank"
                    >{{ 'orderMaterials.fields.privacyPolicy.privacyPolicyLink' | translate }}</a>&nbsp;<span class="required-asterisk">*</span>
                  </label>
              </div>
              <div class="col-12" *ngIf="isSubmittedAndInvalid && orderMaterialsForm.controls['isPrivacyPolicyAccepted']?.errors">
                <c11n-hint-error
                  [hasError]="isSubmittedAndInvalid && orderMaterialsForm.controls['isPrivacyPolicyAccepted'].getError('required')"
                  errorText="{{ 'orderMaterials.errors.privacyPolicy.required' | translate }}" 
                  [hintErrorId]="'privacy-policy-description'" >
                </c11n-hint-error>
              </div>
            </div>
          </div>
        </div>

        <div class="form-section">
          <re-captcha
            formControlName="captchaResponse" 
            siteKey="6LdApAYqAAAAAOQW_aWW2F_vYOZZU36XKu2dWfaU">
          </re-captcha>
          <c11n-hint-error
            [hasError]="(orderMaterialsForm.controls['captchaResponse'].touched || isSubmittedAndInvalid) && 
              orderMaterialsForm.controls['captchaResponse'].getError('required')"
            *ngIf="(orderMaterialsForm.controls['captchaResponse'].touched || isSubmittedAndInvalid) && 
              orderMaterialsForm.controls['captchaResponse'].getError('required')"
            [hintErrorId]="'captchaResponse-description'"
            errorText="{{ 'orderMaterials.errors.reCaptcha.required' | translate }}" 
            >
          </c11n-hint-error>
        </div>

        <c11n-button
          class="submit-btn"
          labelText="{{ 'orderMaterials.submit' | translate }}"
          buttonType="submit"
          [isWaiting]="isSending"
          [attr.aria-describedby]="'submit-description'">
        </c11n-button>
      </form>
    </div>
    <div *ngIf="emailSent" class="submit-confirmation">
      <img src="assets/images/green_checkmark.svg" alt="Green checkmark" class="submit-icon">
      <div class="c11n-text-3xl-headline">Thank you!</div>
      <div class="c11n-text-xl">Your request has been received.</div>
    </div>
  </div>
</div>
