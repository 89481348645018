import { Component, OnDestroy, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
    selector: 'app-thank-you-modal',
    templateUrl: './thank-you-modal.component.html',
    styleUrls: ['./thank-you-modal.component.scss']
})
export class ThankYouModalComponent implements OnInit, OnDestroy {
    constructor(
        private readonly bsModalRef: BsModalRef,
    ) {}

    ngOnInit(): void {
        document.documentElement.classList.add('default-font-size');
    }

    onModalClose() {
        this.bsModalRef.hide();
    }
    
    ngOnDestroy(): void {
        document.documentElement.classList.remove('default-font-size');
    }
}
