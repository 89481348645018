import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { LanguageService } from '../shared/services/language.service';

@Component({
  selector: 'global-header',
  templateUrl: './global-header.component.html',
  styleUrls: ['./global-header.component.scss']
})
export class GlobalHeaderComponent implements OnInit {
  constructor(
    private readonly el: ElementRef,
    private readonly languageService: LanguageService,
    private readonly renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.languageService.initialize(this.renderer);
  }

  onLanguageClick(key: string): void {
    const inputElement = this.el.nativeElement.querySelector(`input[value="${key}"]`);
    if (inputElement) {
      inputElement.checked = true;
      const allowedValues = ['en', 'es'];

      if (allowedValues.includes(key)) {
        this.languageService.setLanguage(key);
      }
    }
  }
}
