import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { OverviewComponent } from "./overview/overview.component";
import { TestimonialsComponent } from "./testimonials/testimonials.component";
import { TermsComponent } from "./global-footer/terms/terms.component";
import { PrivacyPolicyComponent } from "./global-footer/privacy-policy/privacy-policy.component";
import { AboutUsComponent } from "./about-us/about-us.component";
import { Grades23Component } from "./grades/grades2-3/grades2-3.component";
import { GradesK1Component } from "./grades/gradesk-1/grades-k-1.component";
import { MaterialsComponent } from "./materials/materials.component";
import { Grades78Component } from "./grades/grades7-8/grades7-8.component";
import { Grades1112Component } from "./grades/grades11-12/grades11-12.component";
import { Grades910Component } from "./grades/grades9-10/grades9-10.component";
import { Grades45Component } from "./grades/grades4-5/grades4-5.component";
import { Grades6Component } from "./grades/grades6/grades6.component";
import { OneMillionComponent } from "./one-million/one-million.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { WebinarSignUpComponent } from "./webinar-sign-up/webinar-sign-up.component";
import { OrderMaterialsComponent } from "./order-materials/order-materials.component";

const routes: Routes = [
  {
    path: "home",
    component: HomeComponent,
    title: "My Classroom Economy"
  },
  {
    path: "",
    component: HomeComponent,
    title: "My Classroom Economy"
  },
  {
    path: "overview",
    component: OverviewComponent,
    title: "Overview"
  },
  {
    path: "testimonials",
    component: TestimonialsComponent,
    title: "Testimonials"
  },
  {
    path: "terms",
    component: TermsComponent,
    title: "Terms"
  },
  {
    path: "privacy",
    component: PrivacyPolicyComponent,
    title: "Privacy Policy"
  },
  {
    path: "aboutus",
    component: AboutUsComponent,
    title: "About Us"
  },
  {
    path: "webinar",
    component: WebinarSignUpComponent,
    title: "Webinar Sign-Up"
  },
  {
    path: "materials",
    component: MaterialsComponent,
    title: "Materials"
  },
  {
    path: "gradesk-1",
    component: GradesK1Component,
    title: "Grades K to 1"
  },
  {
    path: "grades2-3",
    component: Grades23Component,
    title: "Grades 2 to 3"
  },
  {
    path: "grades4-5",
    component: Grades45Component,
    title: "Grades 4 to 5"
  },
  {
    path: "grade6",
    component: Grades6Component,
    title: "Grade 6"
  },
  {
    path: "grades7-8",
    component: Grades78Component,
    title: "Grades 7 to 8"
  },
  {
    path: "grades9-10",
    component: Grades910Component,
    title: "Grades 9 to 10"
  },
  {
    path: "grades11-12",
    component: Grades1112Component,
    title: "Grades 11 to 12"
  },
  {
    path: "order-materials",
    component: OrderMaterialsComponent,
    title: "Order Materials"
  },
  {
    path: "one-million",
    component: OneMillionComponent,
    title: "One Million"
  },
  { path: '**', pathMatch: 'full',
    component: PageNotFoundComponent,
    title: "Page Not Found"
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
