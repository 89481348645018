export const GRADE_LEVEL_LIST = {
    grade_kindergarten: 'Kindergarten',
    grade_1: '1st grade',
    grade_2: '2nd grade',
    grade_3: '3rd grade',
    grade_4: '4th grade',
    grade_5: '5th grade',
    grade_6: '6th grade',
    grade_7: '7th grade',
    grade_8: '8th grade',
    grade_9: '9th grade',
    grade_10: '10th grade',
    grade_11: '11th grade',
    grade_12: '12th grade',
    grade_other: 'Other'
};

export const COUNTRY_OPTIONS = {
    unitedStates: 'United States',
    canada: 'Canada',
    other: 'Other'
};

export const REFERRAL_LIST = {
    current_prior_paper_program_user: 'Current / prior paper program user',
    vanguard_employee_myce_contact: 'Vanguard Employee / MyCE Contact',
    promotional_email: 'Promotional email',
    through_colleague: 'Through a colleague',
    through_my_school: 'Through my school',
    conference: 'Conference',
    partnership: 'Partnership',
    financial_literacy_month: 'Financial Literacy Month',
    other: 'Other'
};

export const CONFERENCE_LIST = {
    council_for_economic_education: 'Council for Economic Education',
    jumpstart: 'Jump$tart',
    national_association_of_state_treasurers: 'National Association of State Treasurers',
    national_association_of_principals: 'National Association of Principals'
};

export const PARTNERSHIP_LIST = {
    next_gen_personal_finance: 'Next Gen Personal Finance',
    palm_beach_county: 'Palm Beach County',
    state_of_tennessee: 'State of Tennessee'
};

export const SIGNUP_EXTERNAL_LINKS = {
    privacy_policy_url: '/privacy',
    terms_conditions_url: '/terms'
};
