// Source: https://www.faa.gov/air_traffic/publications/atpubs/cnt_html/appendix_a.html (FAA)

export interface UsState {
    name: string;
    stateCode: string;
}

export const UsStateData: UsState[] = [
    { name: "Alabama", stateCode: "AL" },
    { name: "Alaska", stateCode: "AK" },
    { name: "Arizona", stateCode: "AZ" },
    { name: "Arkansas", stateCode: "AR" },
    { name: "American Samoa", stateCode: "AS" },
    { name: "California", stateCode: "CA" },
    { name: "Colorado", stateCode: "CO" },
    { name: "Connecticut", stateCode: "CT" },
    { name: "Delaware", stateCode: "DE" },
    { name: "District of Columbia", stateCode: "DC" },
    { name: "Florida", stateCode: "FL" },
    { name: "Georgia", stateCode: "GA" },
    { name: "Guam", stateCode: "GU" },
    { name: "Hawaii", stateCode: "HI" },
    { name: "Idaho", stateCode: "ID" },
    { name: "Illinois", stateCode: "IL" },
    { name: "Indiana", stateCode: "IN" },
    { name: "Iowa", stateCode: "IA" },
    { name: "Kansas", stateCode: "KS" },
    { name: "Kentucky", stateCode: "KY" },
    { name: "Louisiana", stateCode: "LA" },
    { name: "Maine", stateCode: "ME" },
    { name: "Maryland", stateCode: "MD" },
    { name: "Massachusetts", stateCode: "MA" },
    { name: "Michigan", stateCode: "MI" },
    { name: "Minnesota", stateCode: "MN" },
    { name: "Mississippi", stateCode: "MS" },
    { name: "Missouri", stateCode: "MO" },
    { name: "Montana", stateCode: "MT" },
    { name: "Nebraska", stateCode: "NE" },
    { name: "Nevada", stateCode: "NV" },
    { name: "New Hampshire", stateCode: "NH" },
    { name: "New Jersey", stateCode: "NJ" },
    { name: "New Mexico", stateCode: "NM" },
    { name: "New York", stateCode: "NY" },
    { name: "North Carolina", stateCode: "NC" },
    { name: "North Dakota", stateCode: "ND" },
    { name: "Northern Mariana Islands", stateCode: "MP" },
    { name: "Ohio", stateCode: "OH" },
    { name: "Oklahoma", stateCode: "OK" },
    { name: "Oregon", stateCode: "OR" },
    { name: "Pennsylvania", stateCode: "PA" },
    { name: "Puerto Rico", stateCode: "PR" },
    { name: "Rhode Island", stateCode: "RI" },
    { name: "South Carolina", stateCode: "SC" },
    { name: "South Dakota", stateCode: "SD" },
    { name: "Tennessee", stateCode: "TN" },
    { name: "Texas", stateCode: "TX" },
    { name: "Trust Territories", stateCode: "TT" },
    { name: "Utah", stateCode: "UT" },
    { name: "Vermont", stateCode: "VT" },
    { name: "Virginia", stateCode: "VA" },
    { name: "Virgin Islands", stateCode: "VI" },
    { name: "Washington", stateCode: "WA" },
    { name: "West Virginia", stateCode: "WV" },
    { name: "Wisconsin", stateCode: "WI" },
    { name: "Wyoming", stateCode: "WY"}
]
