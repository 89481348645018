import { environment } from "src/environments/environment";

export const API_DOMAIN_EXTERNAL = {
    url: environment.apiendpoint + 'myceTeacherSignupResource'
};

export const API_PATH = {
    createDigitalMaterial: 'api/myce/material/createMaterial',
    createPaperMaterial: 'api/myce/material/createPaperMaterial',
    validateZipCode: 'api/myce/signup/validateZipCode/'
};
