// Source: https://www.ncbi.nlm.nih.gov/books/NBK7254/table/appe.T1/ (NCBI)

export interface CanadianProvince {
    name: string;
    canadianProvinceCode: string;
}
  
export const CanadianProvinceData: CanadianProvince[] = [
    { name: 'Alberta', canadianProvinceCode: 'AB' },
    { name: 'British Columbia', canadianProvinceCode: 'BC' },
    { name: 'Manitoba', canadianProvinceCode: 'MB' },
    { name: 'New Brunswick', canadianProvinceCode: 'NB' },
    { name: 'Newfoundland and Labrador', canadianProvinceCode: 'NL' },
    { name: 'Northwest Territories', canadianProvinceCode: 'NT' },
    { name: 'Nova Scotia', canadianProvinceCode: 'NS' },
    { name: 'Nunavut', canadianProvinceCode: 'NU' },
    { name: 'Ontario', canadianProvinceCode: 'ON' },
    { name: 'Prince Edward Island', canadianProvinceCode: 'PE' },
    { name: 'Quebec', canadianProvinceCode: 'QC' },
    { name: 'Saskatchewan', canadianProvinceCode: 'SK' },
    { name: 'Yukon', canadianProvinceCode: 'YT' }
];
