import { Transform } from 'class-transformer';
import { IsNotEmpty, Matches, MaxLength } from 'class-validator';
import valZip from "val-zip";
import sanitizeHtml from 'sanitize-html';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const regexPatterns = {
  email: new RegExp('^[\\p{L}0-9._%\'-]+@[\\p{L}0-9.-]+\\.[\\p{L}]{2,4}$', 'u'),
  name: new RegExp('^[\\p{L}0-9 \'-.&_,]+$', 'u'),
  zipCanada: '^(?!.*[DFIOQU])[A-VXY][0-9][A-Z]\\s?[0-9][A-Z][0-9]$',
  domesticPhoneNumber: new RegExp('^\\d{3}-\\d{3}-\\d{4}$'),
  internationalPhoneNumber: new RegExp('^\\+((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\\W*\\d){0,13}\\d$')
}

export const maxLengths = {
  firstName: 20,
  lastName: 35,
  email: 254,
  schoolName: 100,
  streetAddress: 254,
  city: 50,
  zipCode: 12,
  fullName: 50,
  reason: 20,
  subject: 50,
  message: 500,
  numberOfStudents: 1000
}

export function sanitizeInput(value: string): string {
  return sanitizeHtml(value, {
    allowedTags: [],
    allowedAttributes: {},
  }).replaceAll('\'', '&#39;').replaceAll('\"', '&#34;').trim();
}

export function detectIOS() {
  return /iPad|iPhone/.test(navigator.userAgent);
}

export function isValidZip(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (value.length === 0 || valZip(value, 'US') || value.match(regexPatterns.zipCanada)) {
          return null;
      } else {
          return { inValidZip: true };
      }
  }
}

export function isValidUsZip(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (value.length === 0 || valZip(value, 'US')) {
          return null;
      } else {
          return { inValidZip: true };
      }
  }
}

export function isInValidator(values: any[]): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const isIn = values.includes(control.value);
    return isIn ? null : { 'isIn': { value: control.value } };
  };
}

export class ContactUsDto {
  @IsNotEmpty()
  @MaxLength(maxLengths.fullName)
  @Transform(({ value }) => sanitizeInput(value))
  name: string;

  @IsNotEmpty()
  @MaxLength(maxLengths.email)
  @Matches(regexPatterns.email, { message: 'Invalid email address' })
  @Transform(({ value }) => sanitizeInput(value))
  email: string;

  @IsNotEmpty()
  @MaxLength(maxLengths.reason)
  @Transform(({ value }) => sanitizeInput(value))
  reason: string;

  @IsNotEmpty()
  @MaxLength(maxLengths.subject)
  @Transform(({ value }) => sanitizeInput(value))
  subject: string;

  @IsNotEmpty()
  @MaxLength(maxLengths.message)
  @Transform(({ value }) => sanitizeInput(value))
  body: string;

  @IsNotEmpty()
  @Transform(({ value }) => sanitizeInput(value))
  captchaResponse: string;
}
